<template>
  <vs-row>
    <vs-tabs alignment="fixed">
      <vs-tab label="Preliminary Report" v-if="permissions.preliminary.list">
        <div>
          <vs-col vs-w="12" vs-type="flex" vs-justify="flex-end" vs-align="center">
            <vs-button v-if="permissions.preliminary.add" @click="$router.push('/inspection/add/' + $route.params.uuid)">
              <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Add
            </vs-button>
          </vs-col>
          <vs-col vs-w="12">
            <vs-table search stripe :max-items="inspection.per_page" :data="inspection.data">
              <template slot="thead">
                <vs-th>
                  No.
                </vs-th>
                <vs-th>
                  Critical Closed Percentage
                </vs-th>
                <vs-th>
                  Major Closed Percentage
                </vs-th>
                <vs-th>
                  Minor Closed Percentage
                </vs-th>
                <vs-th>
                  Overall Closed Percentage
                </vs-th>
                <vs-th>
                  Created at
                </vs-th>
                <vs-th>
                  Actions
                </vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                  <vs-td :data="inspectionItemOrder(tr)">
                    {{ ((inspection.current_page - 1) * inspection.per_page) + inspectionItemOrder(tr) }}
                  </vs-td>
                  <vs-td :data="tr['Critical Closed Percentage']">
                    {{tr["Critical Closed Percentage"] + (tr["Critical Closed Percentage"] === "---"? "" : "%") }}
                  </vs-td>

                  <vs-td :data="tr['Major Closed Percentage']">
                    {{tr["Major Closed Percentage"] + (tr["Major Closed Percentage"] === "---" ? "" : "%")}}
                  </vs-td>
                  <vs-td :data="tr['Minor Closed Percentage']">
                    {{tr["Minor Closed Percentage"] + (tr["Minor Closed Percentage"] === "---" ? "" : "%")}}
                  </vs-td>
                  <vs-td :data="tr['Overall Closed Percentage']">
                    {{tr["Overall Closed Percentage"] + (tr["Overall Closed Percentage"] === "---" ? "" : "%")}}
                  </vs-td>
                  <vs-td :data="tr['created_at']">
                    {{tr["created_at"] | moment("calendar")}}
                  </vs-td>
                  <vs-td :data="tr">
                    <crud-actions
                      :id="tr.UUID"
                      :permissions="permissions.preliminary"
                      @viewClicked="viewInspectionItem"
                      @editClicked="editInspectionItem"
                    @trashClicked="confirmDelete"></crud-actions>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <Pagination :url="`/reports/inspection/summaries/${this.$route.params.uuid}`" :list-data="inspection" @data-changed="inspection = $event"></Pagination>
          </vs-col>
        </div>
      </vs-tab>
      <vs-tab label="Closing Audit Report" v-if="permissions.closing.list">
        <div>
          <vs-col vs-w="12">
            <vs-table search stripe :max-items="closing.per_page" :data="closing.data">
              <template slot="thead">
                <vs-th>
                  No.
                </vs-th>
                <vs-th>
                  Critical Open
                </vs-th>
                <vs-th>
                  Critical In Progress
                </vs-th>
                <vs-th>
                  Major Open
                </vs-th>
                <vs-th>
                  Major In Progress
                </vs-th>
                <vs-th>
                  Minor Open
                </vs-th>
                <vs-th>
                  Minor In Progress
                </vs-th>
                <vs-th>
                  Overall Open
                </vs-th>
                <vs-th>
                  Overall In Progress
                </vs-th>
                <vs-th>
                  Created at
                </vs-th>
                <vs-th>
                  Updated at
                </vs-th>
                <vs-th>
                  Actions
                </vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                  <vs-td :data="closingItemOrder(tr)">
                    {{ ((closing.current_page - 1) * closing.per_page) + closingItemOrder(tr) }}
                  </vs-td>
                  <vs-td :data="tr['Critical Open']">
                    {{tr["Critical Open"]}}
                  </vs-td>
                  <vs-td :data="tr['Critical In Progress']">
                    {{tr["Critical In Progress"]}}
                  </vs-td>
                  <vs-td :data="tr['Major Open']">
                    {{tr["Major Open"]}}
                  </vs-td>
                  <vs-td :data="tr['Major In Progress']">
                    {{tr["Major In Progress"]}}
                  </vs-td>
                  <vs-td :data="tr['Minor Open']">
                    {{tr["Minor Open"]}}
                  </vs-td>
                  <vs-td :data="tr['Minor In Progress']">
                    {{tr["Minor In Progress"]}}
                  </vs-td>
                  <vs-td :data="tr['Overall Open']">
                    {{tr["Overall Open"]}}
                  </vs-td>
                  <vs-td :data="tr['Overall In Progress']">
                    {{tr["Overall In Progress"]}}
                  </vs-td>
                  <vs-td :data="tr['created_at']">
                    {{tr["created_at"] | moment("calendar")}}
                  </vs-td>
                  <vs-td :data="tr['updated_at']">
                    {{tr["updated_at"] === tr["created_at"] ? "---" : tr["updated_at"] | moment("calendar")}}
                  </vs-td>
                  <vs-td :data="tr">
                    <crud-actions
                      :id="tr.UUID"
                      :permissions="permissions.closing"
                      @viewClicked="viewClosingItem"
                      @editClicked="editClosingItem"
                      :trash="false"></crud-actions>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <Pagination :url="`/reports/closing/${this.$route.params.uuid}`" :list-data="closing" @data-changed="closing = $event"></Pagination>
          </vs-col>
        </div>
      </vs-tab>
      <vs-tab label="Final Condition Survey Report" v-if="permissions.final.list">
        <div>
           <vs-col vs-w="12">
               <vs-table search stripe :max-items="final.per_page" :data="final.data">
                 <template slot="header">
                 </template>
                 <template slot="thead">
                   <vs-th>
                     No.
                   </vs-th>
                   <vs-th>
                     Rig Name
                   </vs-th>
                   <vs-th>
                     Created at
                   </vs-th>
                   <vs-th>
                     Actions
                   </vs-th>
                 </template>

                 <template slot-scope="{data}">
                   <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                     <vs-td :data="finalItemOrder(tr)">
                       {{ ((final.current_page - 1) * final.per_page) + finalItemOrder(tr) }}
                     </vs-td>
                     <vs-td :data="tr.rig.name">
                       {{tr.rig.name}}
                     </vs-td>
                     <vs-td :data="tr['created_at']">
                       {{tr["created_at"] | moment("calendar")}}
                     </vs-td>
                     <vs-td :data="tr">
                       <crud-actions
                         :id="tr.UUID"
                         :permissions="permissions.final"
                         @editClicked="editFinalItem"
                         @viewClicked="showFinalItem"
                         :files="tr.files"
                         @downloadClicked="downloadReport"
                         :edit="false"
                         :trash="false"></crud-actions>
                     </vs-td>
                   </vs-tr>
                 </template>
               </vs-table>
               <Pagination :url="`/reports/final_reports/${this.$route.params.uuid}`" :list-data="final" @data-changed="final = $event"></Pagination>
             </vs-col>
        </div>
      </vs-tab>
    </vs-tabs>
    </vs-row>
</template>

<script>
  import CrudActions from "../../../../../layouts/components/CrudActions";
  import Pagination from "../../../../../layouts/components/Pagination";
  import store2 from "store2";

  export default {
    name: "list",
    components: {
      CrudActions,
      Pagination
    },
    props: {
      inspection: {
        type: Object,
        required: true,
        default: () => {
          return {};
        }
      },
      closing: {
        type: Object,
        required: true,
        default: () => {
          return {};
        }
      },
      final: {
        type: Object,
        required: true,
        default: () => {
          return {};
        }
      },
    },
    data() {
      return {
        newRigData: {
          name: "",
          UUID: "",
          image: null
        },
        addNew: false
      };
    },
    methods: {
      confirmDelete(uuid){
        this.$vs.dialog({
          color: "danger",
          type: "confirm",
          title: "Delete Report",
          text: "Are you sure you want to delete this report?",
          accept: this.deleteReport.bind(null, uuid)
        });
      },
      deleteReport(uuid){
        this.$vs.loading();
        this.$authHTTP.delete("reports/inspection/"+uuid)
          .then(r=>{
            const inspectionIndex = this.inspection.data.findIndex(report => {
              return report.uuid === uuid;
            });
            this.inspection.data.splice(inspectionIndex, 1);
            const closingIndex = this.closing.data.findIndex(report => {
              return report.uuid === uuid;
            });
            this.closing.data.splice(closingIndex, 1);
            const finalIndex = this.final.data.findIndex(report => {
              return report.uuid === uuid;
            });
            this.final.data.splice(finalIndex, 1);
            this.$vs.notify({color:'success',title:'Delete Success',text:'Report is deleted successfully.'});
          })
          .catch(e=>{
            console.log(e);
            this.$vs.notify({color:'error',title:'Delete Fail',text:'Failed to delete report'});
          })
          .finally(()=>{
            this.$vs.loading.close();
          })
      },
      downloadReport(uuid){
       location.href = process.env.VUE_APP_BASE_URL + "/reports/final_reports/download/" + uuid + "?token=" + store2.get("access_token");
      },
      inspectionItemOrder(data) {
        const index = this.inspection.data.findIndex(item => {
          return item.id === data.id;
        });
        return index + 1;
      },
      closingItemOrder(data) {
        const index = this.closing.data.findIndex(item => {
          return item.id === data.id;
        });
        return index + 1;
      },
      finalItemOrder(data) {
        const index = this.final.data.findIndex(item => {
          return item.id === data.id;
        });
        return index + 1;
      },
      getImagePath(data) {
        if (data) {
          return process.env.VUE_APP_BASE_URL.split("api")[0] + data;
        }
        return process.env.VUE_APP_BASE_URL.split("api")[0] + "uploads/images/logo.png";
      },
      viewInspectionItem(uuid) {
        this.$router.push("/inspection/show/" + uuid);
      },
      viewFinalItem(uuid) {
        this.$router.push("/final/show/" + uuid);
      },
      editInspectionItem(uuid){
        this.$router.push("/inspection/edit/" + uuid);
      },
      showFinalItem(uuid){
        this.$router.push("/final/show/" + uuid);
      },
      editFinalItem(uuid){
        this.$router.push("/final/edit/" + uuid);
      },
      viewClosingItem(uuid) {
        this.$router.push("/closing/show/" + uuid);
      },
      editClosingItem(uuid) {
        this.$router.push("/closing/edit/" + uuid);
      }
    },
    computed: {
      permissions(){
        return this.$store.state.auth.permissions.rigs.tabs.inspection;
      }
    },
    mounted() {
      //console.log({inspection: this.inspection, closing: this.closing, final: this.final});
    }
  };
</script>

<style lang="scss">
  .disabled-upload {
    display: none !important;
  }
</style>
