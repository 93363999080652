<template>
  <div>
    <vs-row v-if="rigData">
      <div class="specs">
        <div v-for="(spec, index) in rigData.specs" :key="index">
          <div v-if="spec.subSpecs.length > 0">
            <vs-row class="" vs-justify="start">
              <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
                <vs-card>
                  <div slot="header">
                    <h3>
                      {{spec.title}}
                    </h3>
                  </div>
                  <div v-for="(subspec, index) in spec.subSpecs" :key="index">
                    <vs-row class="" vs-justify="">
                      <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
                        <vs-card>
                          <div slot="header">
                            <h3>
                              {{subspec.title}}
                            </h3>
                          </div>
                          <div v-for="(value, name, index) in subspec" :key="index">
                            <vs-row class="" vs-justify="" v-if="!hiddenKeyword.includes(name)">
                              <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
                                <vs-card>
                                  <div slot="header">
                                    <h3>
                                      {{name}}
                                    </h3>
                                  </div>
                                  <div>
                                    <table>
                                      <thead>
                                      <tr>
                                        <th v-for="(val, key, index) in value" :key="index"
                                            v-if="!hiddenKeyword.includes(key)">
                                          {{key}}
                                        </th>
                                      </tr>
                                      </thead>
                                      <tbody>
                                      <tr>
                                        <td v-for="(val, key, index) in value" :key="index"
                                            v-if="!hiddenKeyword.includes(key)">
                                          {{boolKeywords.find(x => key.toLowerCase().includes(x)) ? getBoolValue(val) :
                                          val}}
                                        </td>
                                      </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </vs-card>
                              </vs-col>
                            </vs-row>
                          </div>
                        </vs-card>
                      </vs-col>
                    </vs-row>
                  </div>
                </vs-card>
              </vs-col>
            </vs-row>
          </div>
          <div v-else>
            <div v-if="spec.subSpecs.length === 0">
              <vs-row class="" vs-justify="start">
                <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
                  <vs-card>
                    <div slot="header">
                      <h3>
                        {{spec.title}}
                      </h3>
                    </div>
                    <div v-for="(v, k, i) in spec" :key="i">
                      <vs-row class="" vs-justify="start" v-if="!hiddenKeyword.includes(k)">
                        <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
                          <vs-card>
                            <div slot="header">
                              <h3>
                                {{k}}
                              </h3>
                            </div>
                            <div>
                              <table>
                                <thead>
                                <tr>
                                  <th v-for="(val, key, index) in v" :key="index" v-if="!hiddenKeyword.includes(key)">
                                    {{key}}
                                  </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                  <td v-for="(val, key, index) in v" :key="index" v-if="!hiddenKeyword.includes(key)">
                                    {{boolKeywords.find(x => key.toLowerCase().includes(x)) ? getBoolValue(val) :
                                    val}}
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </vs-card>
                        </vs-col>
                      </vs-row>
                    </div>
                  </vs-card>
                </vs-col>
              </vs-row>
            </div>
          </div>
        </div>
      </div>
      <vs-row>
        <vs-col vs-w="12" vs-type="flex" vs-justify="flex-end" style="margin-bottom: 20px;">
          <vs-button v-if="permissions.edit" @click="$router.push('/rig/edit/' + $route.params.uuid)"><font-awesome-icon icon="edit"></font-awesome-icon>&nbsp; Edit</vs-button>
        </vs-col>
      </vs-row>
    </vs-row>
    <div v-else class="title">
      <h1>LOADING DATA ...</h1>
    </div>
  </div>
</template>

<script>

  export default {
    name: "specs",
    components: {
    },
    props: {
      rigData :{
        type: Object,
        required: true,
        default: () => null
      }
    },
    data() {
      return {
        boolKeywords: this.$store.state.auth.boolKeywords,
        hiddenKeyword: this.$store.state.auth.hiddenKeyword
      };
    },
    methods: {
      getBoolValue(value) {
        return value === 1 ? "Yes" : "No";
      },
      getImagePath(data) {
        if (data.image) {
          return process.env.VUE_APP_BASE_URL.split("api")[0] + data.image;
        }
        return process.env.VUE_APP_BASE_URL.split("api")[0] + "uploads/images/logo.png";
      }
    },
    computed:{
      permissions(){
        return this.$store.state.auth.permissions.rigs.tabs.specifications;
      }
    }
  };
</script>

<style scoped lang="scss">
  .title {
    margin-top: 20px;
    font-weight: bold;
    font-size: 2rem;
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .image {
    text-align: right;
  }

  .specTitle {
    margin-top: 10px;
    margin-left: 10px;
    font-weight: bold;
    font-size: 1.5rem;
  }

  .subSpecTitle {
    margin-left: 20px;
    font-weight: bold;
    font-size: 1.3rem;
  }

  .subSpec {
    margin-bottom: 20px;
  }

  .data {
    margin-left: 40px;
  }

  .info {
    margin-left: 60px;

    .name {
      text-transform: capitalize;
      font-weight: bold;
    }

    .value {
      color: #000;
    }
  }

  .d-block {
    display: block;
  }

  .card {
    margin: 0 20px;
  }

  table {
    margin: auto;
    width: 100%;

    td, th {
      text-transform: capitalize;
      text-align: center;
      font-size: 1rem;
    }

    th {
      vertical-align: top;
    }

    td {
      vertical-align: middle;
    }
  }

</style>
