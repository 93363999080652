<template>
  <vs-row>
    <vs-tabs alignment="fixed">
      <vs-tab label="Rig Move Audit" v-if="permissions.move.list">
        <div>
          <vs-col vs-w="12" vs-type="flex" vs-justify="flex-end" vs-align="center" v-if="permissions.move.add">
            <vs-button @click="$router.push('/move/add/' + $route.params.uuid)"><font-awesome-icon icon="plus"></font-awesome-icon>&nbsp;Add</vs-button>
          </vs-col>
          <vs-col vs-w="12">
            <vs-table search stripe :max-items="move.per_page" :data="move.data">
              <template slot="header">
              </template>
              <template slot="thead">
                <vs-th>
                  No.
                </vs-th>
                <vs-th>
                  Rig Name
                </vs-th>
                <vs-th>
                  Created at
                </vs-th>
                <vs-th>
                  Actions
                </vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                  <vs-td :data="moveItemOrder(tr)">
                    {{ ((move.current_page - 1) * move.per_page) + moveItemOrder(tr) }}
                  </vs-td>
                  <vs-td :data="tr.rig.name">
                    {{tr.rig.name}}
                  </vs-td>
                  <vs-td :data="tr['created_at']">
                    {{tr["created_at"] | moment("calendar")}}
                  </vs-td>
                  <vs-td :data="tr">
                    <crud-actions
                      :id="tr.UUID"
                      :permissions="permissions.move"
                      @editClicked="editMoveItem"
                      @viewClicked="showMoveItem"
                      :files="tr.files"
                      @trashClicked="confirmDeleteMoveReport"
                      @downloadClicked="downloadMoveReport"
                      :edit="false"
                      :trash="false"></crud-actions>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <Pagination :url="`/reports/move_reports/${this.$route.params.uuid}`" :list-data="move" @data-changed="move = $event"></Pagination>
          </vs-col>
        </div>
      </vs-tab>
      <vs-tab label="Drop Object Survey" v-if="permissions.drop.list">
        <div>
          <vs-col vs-w="12" vs-type="flex" vs-justify="flex-end" vs-align="center" v-if="permissions.drop.add">
            <vs-button @click="$router.push('/drop/add/' + $route.params.uuid)"><font-awesome-icon icon="plus"></font-awesome-icon>&nbsp;Add</vs-button>
          </vs-col>
          <vs-col vs-w="12">
            <vs-table search stripe :max-items="drop.per_page" :data="drop.data">
              <template slot="header">
              </template>
              <template slot="thead">
                <vs-th>
                  No.
                </vs-th>
                <vs-th>
                  Rig Name
                </vs-th>
                <vs-th>
                  Created at
                </vs-th>
                <vs-th>
                  Actions
                </vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                  <vs-td :data="dropItemOrder(tr)">
                    {{ ((drop.current_page - 1) * drop.per_page) + dropItemOrder(tr) }}
                  </vs-td>
                  <vs-td :data="tr.rig.name">
                    {{tr.rig.name}}
                  </vs-td>
                  <vs-td :data="tr['created_at']">
                    {{tr["created_at"] | moment("calendar")}}
                  </vs-td>
                  <vs-td :data="tr">
                    <crud-actions
                      :id="tr.UUID"
                      :permissions="permissions.drop"
                      @editClicked="editDropItem"
                      @viewClicked="viewDropItem"
                      :files="tr.files"
                      @trashClicked="confirmDeleteDropReport"
                      @downloadClicked="downloadDropReport"></crud-actions>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
            <Pagination :url="`/reports/drop_reports/${this.$route.params.uuid}`" :list-data="drop" @data-changed="drop = $event"></Pagination>
          </vs-col>
        </div>
      </vs-tab>
    </vs-tabs>
    </vs-row>
</template>

<script>
  import CrudActions from "../../../../../layouts/components/CrudActions";
  import Pagination from "../../../../../layouts/components/Pagination";
  import store2 from "store2";

  export default {
    name: "list",
    components: {
      CrudActions,
      Pagination
    },
    props: {
      move: {
        type: Object,
        required: true,
        default: () => {
          return {};
        }
      },
      drop: {
        type: Object,
        required: true,
        default: () => {
          return {};
        }
      },
    },
    data() {
      return {
        newRigData: {
          name: "",
          UUID: "",
          image: null
        },
        addNew: false
      };
    },
    methods: {
      confirmDeleteMoveReport($uuid){
        this.$vs.dialog({
          color: "danger",
          type: "confirm",
          title: "Delete File",
          text: "Are you sure you want to delete this report?",
          accept: this.deleteMoveReport.bind(null,$uuid)
        });
      },
      deleteMoveReport($uuid){
        this.$vs.loading();
        this.$authHTTP.delete("/reports/move_reports/delete-report/" + $uuid)
        .then(r=> {
          const index = this.move.data.findIndex(x => x.UUID === $uuid);
          this.move.data.splice(index, 1);
          this.$vs.notify({color:'success',title:'Delete Success',text:'Report is deleted successfully.'});
        })
        .catch(e=>{
          console.log(e);
          this.$vs.notify({color:'danger',title:'Delete Fail',text:'Failed to delete Report'});
        })
        .finally(()=>{
          this.$vs.loading.close();
        })
      },
      confirmDeleteDropReport($uuid){
        this.$vs.dialog({
          color: "danger",
          type: "confirm",
          title: "Delete File",
          text: "Are you sure you want to delete this report?",
          accept: this.deleteDropReport.bind(null,$uuid)
        });
      },
      deleteDropReport($uuid){
        this.$vs.loading();
        this.$authHTTP.delete("/reports/drop_reports/delete-report/" + $uuid)
          .then(r=> {
            const index = this.drop.data.findIndex(x => x.UUID === $uuid);
            this.drop.data.splice(index, 1);
            this.$vs.notify({color:'success',title:'Delete Success',text:'Report is deleted successfully.'});
          })
          .catch(e=>{
            console.log(e);
            this.$vs.notify({color:'danger',title:'Delete Fail',text:'Failed to delete Report'});
          })
          .finally(()=>{
            this.$vs.loading.close();
          })
      },
      downloadMoveReport(uuid){
       location.href = process.env.VUE_APP_BASE_URL + "/reports/move_reports/download/" + uuid + "?token=" + store2.get("access_token");
      },
      downloadDropReport(uuid){
        location.href = process.env.VUE_APP_BASE_URL + "/reports/drop_reports/download/" + uuid + "?token=" + store2.get("access_token");
      },
      moveItemOrder(data) {
        const index = this.move.data.findIndex(item => {
          return item.id === data.id;
        });
        return index + 1;
      },
      dropItemOrder(data) {
        const index = this.drop.data.findIndex(item => {
          return item.id === data.id;
        });
        return index + 1;
      },
      getImagePath(data) {
        if (data) {
          return process.env.VUE_APP_BASE_URL.split("api")[0] + data;
        }
        return process.env.VUE_APP_BASE_URL.split("api")[0] + "uploads/images/logo.png";
      },
      viewMoveItem(uuid) {
        this.$router.push("/move/show/" + uuid);
      },
      editMoveItem(uuid){
        this.$router.push("/move/edit/" + uuid);
      },
      showMoveItem(uuid){
        this.$router.push("/move/show/" + uuid);
      },
      editDropItem(uuid){
        this.$router.push("/drop/edit/" + uuid);
      },
      viewDropItem(uuid){
        this.$router.push("/drop/show/" + uuid);
      },
    },
    computed: {
      permissions(){
        return this.$store.state.auth.permissions.rigs.tabs.moveDrop;
      }
    },
    mounted() {
    }
  };
</script>

<style lang="scss">
  .disabled-upload {
    display: none !important;
  }
</style>
