/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import store2 from "store2";
import appStore from "./store/store";
import App from "./App.vue";
import UUID from "vue-uuid";
import permissions from "./permissions";
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faEye, faEdit, faTrash, faHome, faPlus, faEnvelope, faEnvelopeOpen, faAngleLeft, faAngleRight, faAngleDoubleLeft, faAngleDoubleRight, faTimes, faArrowRight, faArrowLeft, faArrowAltCircleLeft, faArrowAltCircleRight, faSave, faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(faUserSecret, faEye, faEdit, faTrash, faHome, faPlus, faEnvelopeOpen, faEnvelope, faAngleLeft, faAngleRight, faAngleDoubleLeft, faAngleDoubleRight, faTimes, faArrowRight, faArrowLeft, faArrowAltCircleLeft, faArrowAltCircleRight, faSave, faDownload)
Vue.component('font-awesome-icon', FontAwesomeIcon);
const access_token = store2.get("access_token");
const token_type = store2.get("token_type");
const expires_in = store2.get("expires_in");
const user_name = store2.get("user_name");
const user_permission = store2.get("user_permission");
const client_code = store2.get("client_code");
const owner = store2.get("owner");
if(expires_in && expires_in > new Date().getTime()){
  appStore.commit("auth/SET_USER", { access_token, token_type, expires_in, user_name, user_permission, client_code, owner });
}else {
  appStore.dispatch("auth/forceLogout", router).then(()=>{}).catch(()=>{});
}

const loggedIn = setInterval(()=>{
  let timeNow = new Date().getTime();
  if(appStore.state.auth.expires_in > timeNow){
    let time = appStore.state.auth.expires_in - 1000;
    appStore.commit("auth/UPDATE_TIME", time);
  }else {
    clearInterval(loggedIn);
    appStore.dispatch("auth/forceLogout", router).then(()=>{}).catch(()=>{});
  }
}, 1000);

window.addEventListener("storage", function (e) {
  if(e.oldValue && (e.newValue !== e.oldValue) && e.newValue !== appStore.state.auth[e.key]){
    appStore.dispatch("auth/forceLogout", router).then(()=>{}).catch(()=>{});
  }
}, false);

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
Vue.use(Vuesax);

// Vue.use({
//   "v-validate": ValidationProvider
// });

// axios
import { noAuthHTTP, authHTTP } from "./axios.js";

Vue.prototype.$noAuthHTTP = noAuthHTTP;
Vue.prototype.$authHTTP = authHTTP;

Vue.prototype.$authHTTP.interceptors.request.use(request => {
  request.params = {
    token: store2.get("access_token")
  }
  return request;
});
// Theme Configurations
import "../themeConfig.js";

// Globally Registered Components
import "./globalComponents.js";

// Styles: SCSS
import "./assets/scss/main.scss";

// Tailwind
import "@/assets/css/main.css";

// Vue Router
import router from "./router";

// Vuex Store
import store from "./store/store";

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);

// Vee Validate
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
Vue.use(require('vue-moment'));

// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

// Feather font icon
require("./assets/css/iconfont.css");

Vue.prototype.$permissions = permissions;
appStore.dispatch("auth/user_permissions", permissions).then(()=>{}).catch(()=>{});

Vue.config.productionTip = false;
Vue.use(UUID);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
