<template>
  <vs-row>
    <div>
      <vs-col vs-w="12">
        <vs-table search stripe :max-items="listData.per_page" :data="listData.data">
          <template slot="thead">
            <vs-th>
              No.
            </vs-th>
            <vs-th>
              Critical Open
            </vs-th>
            <vs-th>
              Critical In Progress
            </vs-th>
            <vs-th>
              Major Open
            </vs-th>
            <vs-th>
              Major In Progress
            </vs-th>
            <vs-th>
              Minor Open
            </vs-th>
            <vs-th>
              Minor In Progress
            </vs-th>
            <vs-th>
              Overall Open
            </vs-th>
            <vs-th>
              Overall In Progress
            </vs-th>
            <vs-th>
              Created at
            </vs-th>
            <vs-th>
              Updated at
            </vs-th>
            <vs-th>
              Actions
            </vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

              <vs-td :data="itemOrder(tr)">
                {{ ((listData.current_page - 1) * listData.per_page) + itemOrder(tr) }}
              </vs-td>
              <vs-td :data="tr['Critical Open']">
                {{tr["Critical Open"]}}
              </vs-td>
              <vs-td :data="tr['Critical In Progress']">
                {{tr["Critical In Progress"]}}
              </vs-td>
              <vs-td :data="tr['Major Open']">
                {{tr["Major Open"]}}
              </vs-td>
              <vs-td :data="tr['Major In Progress']">
                {{tr["Major In Progress"]}}
              </vs-td>
              <vs-td :data="tr['Minor Open']">
                {{tr["Minor Open"]}}
              </vs-td>
              <vs-td :data="tr['Minor In Progress']">
                {{tr["Minor In Progress"]}}
              </vs-td>
              <vs-td :data="tr['Overall Open']">
                {{tr["Overall Open"]}}
              </vs-td>
              <vs-td :data="tr['Overall In Progress']">
                {{tr["Overall In Progress"]}}
              </vs-td>
              <vs-td :data="tr['created_at']">
                {{tr["created_at"] | moment("calendar")}}
              </vs-td>
              <vs-td :data="tr['updated_at']">
                {{tr["updated_at"] === tr["created_at"] ? "---" : tr["updated_at"] | moment("calendar")}}
              </vs-td>
              <vs-td :data="tr">
                <crud-actions
                  :id="tr.UUID"
                  :permissions="permissions"
                  @viewClicked="viewItem"
                  @editClicked="editItem"
                  :trash="false"></crud-actions>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <Pagination :url="`/reports/qshe/${this.$route.params.uuid}`" :list-data="listData" @data-changed="listData = $event"></Pagination>
      </vs-col>
    </div>
  </vs-row>
</template>

<script>
  import CrudActions from "../../../../../layouts/components/CrudActions";
  import Pagination from "../../../../../layouts/components/Pagination";

  export default {
    name: "list",
    components: {
      CrudActions,
      Pagination
    },
    props: {
      listData :{
        required: true,
        type: Object,
        default: () => {
          return {};
        }
      }
    },
    data() {
      return {
        newRigData: {
          name: "",
          UUID: "",
          image: null
        },
        addNew: false
      };
    },
    methods: {
      itemOrder(data) {
        const index = this.listData.data.findIndex(item => {
          return item.id === data.id;
        });
        return index + 1;
      },
      getImagePath(data) {
        if (data) {
          return process.env.VUE_APP_BASE_URL.split("api")[0] + data;
        }
        return process.env.VUE_APP_BASE_URL.split("api")[0] + "uploads/images/logo.png";
      },
      viewItem(uuid) {
        this.$router.push("/qshe/show/" + uuid);
      },
      editItem(uuid){
        this.$router.push("/qshe/edit/" + uuid);
      }
    },
    computed: {
      permissions(){
        return this.$store.state.auth.permissions.rigs.tabs.qshe.list;
      }
    },
    mounted() {
    }
  };
</script>

<style lang="scss">
  .disabled-upload {
    display: none !important;
  }
</style>
