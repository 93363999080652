<template>
    <vs-row>
     <vs-col vs-w="12">
       <h1>{{rigData.specs && rigData.specs[0].title}}</h1>
     </vs-col>
      <vs-col vs-w="12">
        <h2>{{rigData.specs && rigData.specs[0].subSpecs[0].title}}</h2>
      </vs-col>
      <vs-col vs-w="12">
        <h3>{{title}}</h3>
      </vs-col>
      <vs-col vs-w="12" class="step-data" v-for="(value, key, index) in componentData" :key="index">
        <div class="input-container" v-if="!hiddenKeyword.includes(key)">
          <vs-input class="input" :label-placeholder="titleCase(key)" v-model="componentData[key]"/>
        </div>
      </vs-col>
    </vs-row>
</template>

<script>
    export default {
        name: "general-data",
      props :{
        rigData: {
          type: Object,
          required: true
        }
      },
      data(){
          return {
            componentData: {},
            title: "General Data",
            boolKeywords: this.$store.state.auth.boolKeywords,
            hiddenKeyword: this.$store.state.auth.hiddenKeyword
          }
      },
      methods:{
          titleCase(str) {
            let splitStr = str.split(' ');
            for (let i = 0; i < splitStr.length; i++) {
              splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
            }
            return splitStr.join(' ');
          }
      },
      watch:{
          rigData: {
            deep: true,
            immediate: false,
            handler(value){
              this.componentData = value.specs[0].subSpecs[0][this.title];
            }
          },
        componentData:{
          deep: true,
          immediate: true,
          handler(value){
            this.rigData.name = value.name;
            if(value.name && value.name.length > 3){
              this.$emit("can-continue", {value: true});
            }else {
              this.$emit("can-continue", {value: false});
            }
          }
        }
      },
      beforeDestroy() {
          //this.$store.dispatch("auth/set_data", this.rigData);
      }
    };
</script>

<style scoped lang="scss">
.input-container {
  margin: 20px;
  .input {
    width: 50%;
    margin: auto;
  }
}
  h1,h2,h3{
    text-align: center;
  }

  .step-data {
    margin-top: 20px;
  }
</style>
