<template>
  <vs-row>
    <vs-col vs-w="12" style="margin-bottom: 30px;">
      <Breadcrumb></Breadcrumb>
    </vs-col>
    <vs-col vs-w="6" v-if="$store.state.auth.owner">
      <vs-select label="Client" v-model="visit.client_id" placeholder="Choose Client" class="w-100 p-3">
        <vs-select-item value="" disabled text="Choose Client"></vs-select-item>
        <vs-select-item v-for="(client, index) in clients" :key="index" :value="client.id"
                        :text="client.name"></vs-select-item>
      </vs-select>
    </vs-col>
    <vs-col :vs-w="visit.rig_id === '0' ? 3 : 6">
      <vs-select label="Rig" v-model="visit.rig_id" :key="updateRig" placeholder="Choose Rig" class="w-100 p-3">
        <vs-select-item value="" disabled text="Choose Rig"></vs-select-item>
        <vs-select-item v-for="(rig, index) in clientRigs" :key="index" :value="rig.id"
                        :text="rig.name"></vs-select-item>
        <vs-select-item value="0" text="Other"></vs-select-item>
      </vs-select>
    </vs-col>
    <vs-col vs-w="3" v-if="visit.rig_id === '0'">
      <vs-input class="p-3 w-100" label="Other Rig Name" v-model="visit.other_rig"></vs-input>
    </vs-col>
    <vs-col vs-w="6">
      <vs-select label="Type" v-model="visit.visit_type_id" placeholder="Choose Type" class="w-100 p-3">
        <vs-select-item value="" disabled text="Choose Type"></vs-select-item>
        <vs-select-item v-for="(type, index) in types" :key="index" :value="type.id"
                        :text="type.name"></vs-select-item>
      </vs-select>
    </vs-col>
      <vs-col vs-w="6" class="my-3">
        <small class="ml-3">Date</small>
          <DatePicker v-model="visit.date" :highlighted="highLightedDates" :disabled-dates="disabledDates" :format="customFormatter" class="mx-3"></DatePicker>
    </vs-col>
    <vs-col vs-w="12" class="m-2">
      <vs-textarea label="Description" height="100%" rows="5" v-model="visit.description"></vs-textarea>
    </vs-col>
    <vs-col vs-w="12" vs-type="flex" vs-justify="flex-end" class="pr-3">
      <vs-button @click="$router.go(-1)" class="mr-1"><font-awesome-icon icon="arrow-left"></font-awesome-icon>&nbsp;Back</vs-button>
      <vs-button :disabled="invalidData" @click="confirmSave"><font-awesome-icon icon="save"></font-awesome-icon>&nbsp;Save</vs-button>
    </vs-col>
  </vs-row>
</template>

<script>
  import Breadcrumb from "../../../layouts/components/Breadcrumb";
  import DatePicker from "vuejs-datepicker";
  export default {
    name: "edit",
    components: {
      Breadcrumb,
      DatePicker
    },
    data(){
      return {
        clients: [],
        selectedClient: "",
        rigs: [],
        selectedRig: "",
        selectedDate: null,
        visit: {},
        types: [],
        updateRig: 0,
        disabledDates:{
          to: new Date(),
          dates: []
        },
        highLightedDates: {
          dates: []
        },
      }
    },
    methods: {
      getVisits(){
        this.$vs.loading();
        this.$authHTTP.get(`visits/list?client=${this.selectedClient}&rig=${this.selectedRig}`)
          .then(r => {
            this.$vs.loading.close()
            const data = r.data;
            for(const i in data){
              const item = data[i];
              this.highLightedDates.dates.push(new Date(item.date));
            }
          })
          .catch(e => {
            this.$vs.loading.close()
            console.log(e);
          });
      },
      getTypes(){
        this.$vs.loading();
        this.$authHTTP.get("types/list")
          .then(r => {
            this.$vs.loading.close()
            this.types = r.data;
          })
          .catch(e => {
            this.$vs.loading.close()
            console.log(e);
          });
      },
      getData(){
        this.$vs.loading();
        this.$authHTTP.get(`visits/show/${this.$route.params.uuid}`)
          .then(r => {
            this.visit = r.data;
            if(!this.visit.rig_id){
              this.visit.rig_id = "0";
            }
          })
          .catch(e => {
            console.log(e);
          })
          .finally(()=>{
            this.$vs.loading.close();
          })
      },
      confirmSave(){
        this.$vs.dialog({
          title: "Confirm",
          text: "Are you sure that you want to save this item?",
          color: "primary",
          acceptText: "I'm sure",
          accept: () => this.saveData(),
          type: "confirm"
        });
      },
      saveData(){
        this.$vs.loading();
        const payload = Object.assign(this.visit, {date: new Date(this.visit.date).toISOString()})
        this.$authHTTP.post(`visits/update/${this.$route.params.uuid}`, payload)
          .then(r => {
            this.$vs.notify({
              title: 'Success',
              text: r.data,
              color: 'primary'
            });
            if (this.isCurrentUser){
              this.$store.dispatch("auth/logout").then(()=> this.$router.push("/")).catch(e=> {
                console.log(e);});
            }
            this.$router.go(-1);
          })
          .catch(e => {
            console.log(e);
            this.$vs.notify({
              title: 'Error',
              text: e,
              color: 'danger'
            });
          })
          .finally(()=>{
            this.$vs.loading.close()
          });
      },
      getClients(){
        this.$vs.loading();
        this.$authHTTP.get("clients/all")
          .then(r => {
            this.$vs.loading.close()
            this.clients = r.data.filter(x=> x.id !== 1);
          })
          .catch(e => {
            this.$vs.loading.close()
            console.log(e);
          });
      },
      getRigs(){
        this.$vs.loading();
        this.$authHTTP.get("rigs/all")
          .then(r => {
            this.$vs.loading.close()
            this.rigs = r.data;
          })
          .catch(e => {
            this.$vs.loading.close()
            console.log(e);
          });
      },
      customFormatter(date) {
        return this.$moment(date).format('DD/M/YYYY');
      },
    },
    computed:{
      clientRigs(){
        return this.rigs.filter(x => x.client_id === this.visit.client_id);
      },
      invalidData(){
        return !(this.visit.client_id && this.visit.rig_id && this.visit.date && this.visit.visit_type_id && this.visit.description.trim());
      }
    },
    watch: {
      "visit.client_id"() {
        this.updateRig++;
      }
    },
    mounted() {
      this.getClients();
      this.getRigs();
      this.getData();
      this.getTypes();
      this.getVisits();
    }
  };
</script>

<style lang="scss">
  .w-100 {
    width: 100% !important;
  }
  .vdp-datepicker .cell.day.highlighted{
    background: rgba(var(--vs-danger), 0.7);
  }
</style>
