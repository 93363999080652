<template>
  <div>
    <Breadcrumb></Breadcrumb>
    <vs-row v-if="rigData">
      <vs-col vs-w="6">
        <div class="title">
          <p>{{ rigData.name }}</p>
        </div>
      </vs-col>
      <vs-col vs-w="6">
        <div class="image">
          <img :src="getImagePath(rigData)" alt="Rig Image" width="100" height="100">
        </div>
        <div class="text-right" v-if="permissions.edit">
          <vs-button @click="showLogoUploadOverlay = true">Edit</vs-button>
        </div>
      </vs-col>
      <vs-collapse accordion type="margin" style="width: 100%;">
        <vs-collapse-item v-if="permissions.tabs.specifications.show">
          <div slot="header">
            Specifications
          </div>
          <Specifications :rig-data="rigData" class="fixed-height"></Specifications>
        </vs-collapse-item>
        <vs-collapse-item v-if="permissions.tabs.inspection.show">
          <div slot="header">
            Rig Inspection (Full Condition Survey)
          </div>
          <InspectionList :inspection="inspection" :closing="closing" :final="final"
                          class="fixed-height"></InspectionList>
        </vs-collapse-item>
        <vs-collapse-item v-if="permissions.tabs.audit.show">
          <div slot="header">
            Rig Audit
          </div>
          <audit-list :inspection="audit" :closing="auditClosing" :final="auditFinal" :list="audit"></audit-list>
        </vs-collapse-item>
        <vs-collapse-item v-if="permissions.tabs.acceptance.show">
          <div slot="header">
            Rig Acceptance
          </div>
          <acceptance-list :list="acceptance"></acceptance-list>
        </vs-collapse-item>
        <vs-collapse-item v-if="permissions.tabs.stopCard.show">
          <div slot="header">
            Stop Card (HSE)
          </div>
          <div></div>
        </vs-collapse-item>
        <vs-collapse-item v-if="permissions.tabs.qshe.show">
          <div slot="header">
            QHSE
          </div>
          <QSHEList :list-data="qshe" style="width: 100%; overflow: auto"></QSHEList>
        </vs-collapse-item>
        <vs-collapse-item v-if="permissions.tabs.moveDrop.show">
          <div slot="header">
            Rig Move Audit & Drop Object Survey
          </div>
          <move-and-drop-list :move="move" :drop="drop"></move-and-drop-list>
        </vs-collapse-item>
      </vs-collapse>
      <vs-row>
        <vs-col vs-w="12" vs-type="flex" vs-justify="flex-end">
          <vs-button @click="$router.go(-1)">
            <font-awesome-icon icon="arrow-left"></font-awesome-icon>&nbsp; Back
          </vs-button>
        </vs-col>
      </vs-row>
      <vs-popup class="holamundo" title="Change Rig Image" :active.sync="showLogoUploadOverlay">
        <vs-row vs-type="flex" vs-align="center" vs-justify="center" class="h-100">
          <vs-col vs-w="6" vs-sm="12">
            <vs-upload
              :action="getUploadImageEndPoint"
              automatic
              text="Upload Image"
              limit="1"
              fileName="image"
              accept="image/*"
              :data="{UUID: $route.params.uuid, update: 1}"
              @on-success="successUpload"
              @on-error="failedUpload"
              @on-delete="deletedUpload"
            />
          </vs-col>
        </vs-row>
      </vs-popup>
    </vs-row>
    <div v-else class="title">
      <h1>LOADING DATA ...</h1>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../../layouts/components/Breadcrumb";
import Specifications from "./show/specs";
import InspectionList from "./show/inspections/list";
import QSHEList from "./show/qshe/list";
import auditList from "./show/audit/list";
import acceptanceList from "./show/acceptance/list";
import moveAndDropList from "./show/moveAndDrop/list";
import store2 from "store2";

export default {
  name: "show",
  components: {
    Breadcrumb,
    Specifications,
    InspectionList,
    QSHEList,
    auditList,
    acceptanceList,
    moveAndDropList
  },
  data() {
    return {
      rigData: null,
      inspection: {},
      closing: {},
      auditClosing: {},
      final: {},
      auditFinal: {},
      qshe: {},
      audit: {},
      acceptance: {},
      move: {},
      drop: {},
      boolKeywords: this.$store.state.auth.boolKeywords,
      hiddenKeyword: this.$store.state.auth.hiddenKeyword,
      showLogoUploadOverlay: false
    };
  },
  methods: {
    successUpload(data) {
      this.rigData.image = data.currentTarget.responseText;
      this.showLogoUploadOverlay = false;
      this.$vs.notify({color: 'success', title: 'Upload Success', text: 'Image is uploaded successfully.'});
    },
    failedUpload() {
      this.$vs.notify({color: 'danger', title: 'Upload Failed', text: 'Unable to upload image.'});
    },
    deletedUpload() {
      this.$vs.loading();
      this.$authHTTP.post(this.getUploadImageEndPoint, {UUID: this.$route.params.uuid, update: 2})
        .then(() => {
          this.rigData.image = null;
        })
        .catch(error => {
          console.log({error});
        })
        .finally(() => {
          this.$vs.loading.close();
        })
      this.$vs.notify({color: 'success', title: 'Delete Success', text: 'Image is deleted successfully.'});
    },
    getDrop() {
      this.$vs.loading();
      this.$authHTTP.get("reports/drop_reports/" + this.$route.params.uuid)
        .then(r => {
          this.$vs.loading.close();
          this.drop = r.data;
        })
        .catch(e => {
          this.$vs.loading.close();
          console.log(e);
        });
    },
    getMove() {
      this.$vs.loading();
      this.$authHTTP.get("reports/move_reports/" + this.$route.params.uuid)
        .then(r => {
          this.$vs.loading.close();
          this.move = r.data;
        })
        .catch(e => {
          this.$vs.loading.close();
          console.log(e);
        });
    },
    getFinal() {
      this.$vs.loading();
      this.$authHTTP.get("reports/final_reports/" + this.$route.params.uuid)
        .then(r => {
          this.$vs.loading.close();
          this.final = r.data;
        })
        .catch(e => {
          this.$vs.loading.close();
          console.log(e);
        });
    },
    getAuditFinal() {
      this.$vs.loading();
      this.$authHTTP.get("reports/audit_final_reports/" + this.$route.params.uuid)
        .then(r => {
          this.$vs.loading.close();
          this.auditFinal = r.data;
        })
        .catch(e => {
          this.$vs.loading.close();
          console.log(e);
        });
    },
    getData() {
      this.$vs.loading();
      this.$authHTTP.get("rigs/" + this.$route.params.uuid)
        .then(r => {
          this.$vs.loading.close();
          this.rigData = r.data;
        })
        .catch(e => {
          this.$vs.loading.close();
          console.log(e);
        });
    },
    getInspection() {
      this.$vs.loading();
      this.$authHTTP.get("reports/inspection/summaries/" + this.$route.params.uuid)
        .then(r => {
          this.$vs.loading.close();
          this.inspection = r.data;
        })
        .catch(e => {
          this.$vs.loading.close();
          console.log(e);
        });
    },
    getClosing() {
      this.$vs.loading();
      this.$authHTTP.get("reports/closing/summaries/" + this.$route.params.uuid)
        .then(r => {
          this.$vs.loading.close();
          this.closing = r.data;
        })
        .catch(e => {
          this.$vs.loading.close();
          console.log(e);
        });
    },
    getAuditClosing() {
      this.$vs.loading();
      this.$authHTTP.get("reports/audit_closing/summaries/" + this.$route.params.uuid)
        .then(r => {
          this.$vs.loading.close();
          this.auditClosing = r.data;
        })
        .catch(e => {
          this.$vs.loading.close();
          console.log(e);
        });
    },
    getQSHE() {
      this.$vs.loading();
      this.$authHTTP.get("reports/qshe/summaries/" + this.$route.params.uuid)
        .then(r => {
          this.$vs.loading.close();
          this.qshe = r.data;
        })
        .catch(e => {
          this.$vs.loading.close();
          console.log(e);
        });
    },
    getAudit() {
      this.$vs.loading();
      this.$authHTTP.get("reports/audit/summaries/" + this.$route.params.uuid)
        .then(r => {
          this.$vs.loading.close();
          this.audit = r.data;
        })
        .catch(e => {
          this.$vs.loading.close();
          console.log(e);
        });
    },
    getAcceptance() {
      this.$vs.loading();
      this.$authHTTP.get("reports/acceptance/summaries/" + this.$route.params.uuid)
        .then(r => {
          this.$vs.loading.close();
          this.acceptance = r.data;
        })
        .catch(e => {
          this.$vs.loading.close();
          console.log(e);
        });
    },
    getBoolValue(value) {
      return value === 1 ? "Yes" : "No";
    },
    getImagePath(data) {
      if (data.image) {
        return process.env.VUE_APP_BASE_URL.split("api")[0] + data.image;
      }
      return process.env.VUE_APP_BASE_URL.split("api")[0] + "uploads/images/logo.png";
    }
  },
  computed: {
    permissions() {
      return this.$store.state.auth.permissions.rigs;
    },
    getUploadImageEndPoint() {
      return process.env.VUE_APP_BASE_URL + "/rigs/upload/image?token=" + store2.get("access_token");
    },
  },
  beforeMount() {
    this.getData();
    this.getInspection();
    this.getClosing();
    this.getAuditClosing();
    this.getQSHE();
    this.getFinal();
    this.getAuditFinal();
    this.getAudit();
    this.getMove();
    this.getDrop();
    this.getAcceptance();
  }
};
</script>

<style scoped lang="scss">
.title {
  margin-top: 20px;
  font-weight: bold;
  font-size: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.image {
  text-align: right;
}

.specTitle {
  margin-top: 10px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 1.5rem;
}

.subSpecTitle {
  margin-left: 20px;
  font-weight: bold;
  font-size: 1.3rem;
}

.subSpec {
  margin-bottom: 20px;
}

.data {
  margin-left: 40px;
}

.info {
  margin-left: 60px;

  .name {
    text-transform: capitalize;
    font-weight: bold;
  }

  .value {
    color: #000;
  }
}

.d-block {
  display: block;
}

.card {
  margin: 0 20px;
}

table {
  margin: auto;
  width: 100%;

  td, th {
    text-transform: capitalize;
    text-align: center;
    font-size: 1rem;
  }

  th {
    vertical-align: top;
  }

  td {
    vertical-align: middle;
  }
}

.fixed-height {
  height: 500px;
  overflow: auto;
}

/*#showLogoUploadOverlay {
  position: fixed;
  top: -100%;
  right: 0;
  left: 0;
  bottom: 100%;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.5s;
}

#showLogoUploadOverlay.show {
  top: 0;
  bottom: 0;
}*/

.h-100 {
  height: 100%;
}
</style>
