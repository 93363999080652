<template>
  <vs-row>
    <vs-col vs-w="12" style="margin-bottom: 30px;">
      <Breadcrumb></Breadcrumb>
    </vs-col>
    <vs-col vs-w="6">
      <vs-input v-model="user.name" label="Name" class="w-100 p-3"></vs-input>
    </vs-col>
    <vs-col vs-w="6">
      <vs-input v-model="user.email" label="Email" class="w-100 p-3"></vs-input>
    </vs-col>
    <vs-col vs-w="6" v-if="$store.state.auth.owner">
      <vs-select label="Client" v-model="user.client_id" placeholder="Choose Client" class="w-100 p-3">
        <vs-select-item v-for="(client, index) in clients" :key="index" :value="client.id"
                        :text="client.name"></vs-select-item>
      </vs-select>
    </vs-col>
    <vs-col vs-w="6" v-if="$store.state.auth.owner">
      <vs-select label="Permission" v-model="user.permission_id" placeholder="Choose Permission" class="w-100 p-3">
        <vs-select-item v-for="(permission, index) in allPermissions" :key="index" :value="permission.id"
                        :text="permission.type.toUpperCase()"></vs-select-item>
      </vs-select>
    </vs-col>
    <vs-col vs-w="6">
      <vs-input v-model="user.password" type="password" autocomplete="new-password" label="Password" class="w-100 p-3"></vs-input>
    </vs-col>
    <vs-col vs-w="6">
      <vs-input v-model="user.passwordConfirmation" type="password" autocomplete="new-password" label="Confirm Password" class="w-100 p-3"></vs-input>
    </vs-col>
    <vs-col vs-w="12" vs-type="flex" vs-justify="flex-end" vs-align="center" style="margin-top: 100px">
      <vs-button @click="$router.go(-1)" style="margin-right: 10px">Back</vs-button>
      <vs-button @click="confirmSave" :disabled="invalidData">Save</vs-button>
    </vs-col>
  </vs-row>
</template>

<script>
  import Breadcrumb from "../../../layouts/components/Breadcrumb";
  export default {
    name: "add",
    components: {
      Breadcrumb
    },
    data(){
      return {
        user: {
          name: "",
          email: "",
          client_id: "",
          permission_id: "",
          UUID: this.$uuid.v4(),
          password: "",
          passwordConfirmation: ""
        },
        clients: [],
        allPermissions: [],
        changePassword: false,
        isCurrentUser: false
      }
    },
    methods: {
      generateCode(){
        const random = Math.random().toString(36).substr(2,7);
        const time = new Date().getTime().toString().substr(6);
        this.user.code = random + time;
      },
      confirmSave(){
        this.$vs.dialog({
          title: "Confirm",
          text: "Are you sure that you want to save this item?",
          color: "primary",
          acceptText: "I'm sure",
          accept: () => this.isCurrentUser ? this.confirmLogout() : this.saveData(),
          type: "confirm"
        });
      },
      confirmLogout(){
        this.$vs.dialog({
          title: "Confirm",
          text: "This is your account data and to change it, you will be logged out.",
          color: "primary",
          acceptText: "I'm sure",
          accept: () => this.saveData(),
          type: "confirm"
        });
      },
      saveData(){
        this.$vs.loading();
        const payload = {
          name: this.user.name.trim(),
          email: this.user.email.trim(),
          permission_id: this.user.permission_id,
          client_id: this.user.client_id,
          password: this.user.password.trim(),
          uuid: this.$uuid.v4()
        }
        this.$authHTTP.post(`users/save`, payload)
          .then(r => {
            this.$vs.notify({
              title: 'Success',
              text: r.data,
              color: 'primary'
            });
            if (this.isCurrentUser){
              this.$store.dispatch("auth/logout").then(()=> this.$router.push("/")).catch(e=> {
                console.log(e);});
            }
            this.$router.go(-1);
          })
          .catch(e => {
            console.log(e);
            this.$vs.notify({
              title: 'Error',
              text: e,
              color: 'danger'
            });
          })
          .finally(()=>{
            this.$vs.loading.close()
          });
      },
      getClients(){
        this.$vs.loading();
        this.$authHTTP.get("clients/all")
          .then(r => {
            this.$vs.loading.close()
            this.clients = r.data;
          })
          .catch(e => {
            this.$vs.loading.close()
            console.log(e);
          });
      },
      getPermissions(){
        this.$vs.loading();
        this.$authHTTP.get("permissions/list")
          .then(r => {
            this.$vs.loading.close()
            this.allPermissions = r.data;
          })
          .catch(e => {
            this.$vs.loading.close()
            console.log(e);
          });
      }
    },
    computed:{
      invalidData(){
        if(this.user.name.trim()
          && this.user.email.trim()
          && this.user.UUID
          && this.user.permission_id
          && this.user.client_id
          && this.user.password.trim()
          && this.user.passwordConfirmation.trim()
          && (this.user.password.trim() === this.user.passwordConfirmation.trim())
        ){
            return false;
          }
        return true;
      }
    },
    mounted() {
      this.getClients();
      this.getPermissions();
    }
  };
</script>

<style scoped lang="scss">
  .w-100 {
    width: 100% !important;
  }
</style>
