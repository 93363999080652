<template>
  <vs-row>
    <vs-col vs-w="12">
      <h1>{{rigData.specs && rigData.specs[2].title}}</h1>
    </vs-col>
    <!--<vs-col vs-w="12">
      <h2>{{rigData.specs && rigData.specs[1].subSpecs[1].title}}</h2>
    </vs-col>-->
    <vs-col vs-w="12">
      <h3>{{title}}</h3>
    </vs-col>
    <vs-col vs-w="12" class="step-data" v-for="(value, key, index) in componentData" :key="index">
      <div class="input-container" v-if="!hiddenKeyword.includes(key) && !boolKeywords.find(x => key.toLowerCase().includes(x))">
        <vs-input class="input" :label-placeholder="titleCase(key)" v-model="componentData[key]"/>
      </div>
      <div class="input-container" v-if="!hiddenKeyword.includes(key) && boolKeywords.find(x => key.toLowerCase().includes(x))">
        <div class="input">
          <ul class="leftx">
            <li class="modelx">
              {{titleCase(key)}}
            </li>
            <li>
              <vs-radio :vs-name="key" v-model="componentData[key]" vs-value="1">Yes</vs-radio>
            </li>
            <li>
              <vs-radio :vs-name="key" v-model="componentData[key]" vs-value="0">No</vs-radio>
            </li>
          </ul>
        </div>
      </div>
    </vs-col>
  </vs-row>
</template>

<script>
  export default {
    name: "dead-line-anchor",
    props :{
      rigData: {
        type: Object,
        required: true
      }
    },
    data(){
      return {
        componentData: {},
        title: "Dead Line Anchor",
        boolKeywords: this.$store.state.auth.boolKeywords,
        hiddenKeyword: this.$store.state.auth.hiddenKeyword
      }
    },
    methods:{
      titleCase(str) {
        let splitStr = str.split(' ');
        for (let i = 0; i < splitStr.length; i++) {
          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
      }
    },
    watch:{
      rigData: {
        deep: true,
        immediate: true,
        handler(value){
          this.componentData = value.specs[2][this.title];
        }
      },
      componentData:{
        deep: true,
        immediate: true,
        handler(value){
          this.$emit("can-continue", {value: true});
        }
      }
    },
    beforeDestroy() {
      //this.$store.dispatch("auth/set_data", this.rigData);
    }
  };
</script>

<style scoped lang="scss">
  .input-container {
    margin: 20px;
    .input {
      width: 50%;
      margin: auto;
    }
  }
  h1,h2,h3{
    text-align: center;
  }
  .step-data {
    margin-top: 20px;
  }
  li:not(.modelx) {
    display: inline-block;
    margin: 10px;
  }

</style>
