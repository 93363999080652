<template>
  <vs-row>
    <vs-col vs-w="12" style="margin-bottom: 20px;">
      <Breadcrumb></Breadcrumb>
    </vs-col>
    <vs-col vs-w-12>
      <HorizontalStepper
        :steps="steps"
        :keep-alive="true"
        :top-buttons="true"
        @completed-step="completeStep"
        @active-step="isStepActive"
        @stepper-finished="confirmSave"
        :rig-data="rigData"
        class="popup"
      ></HorizontalStepper>
    </vs-col>
  </vs-row>
</template>

<script>
  import Breadcrumb from "../../../layouts/components/Breadcrumb";
  import HorizontalStepper from "vue-stepper";
  import general_data from "../rigSteps/general-data";
  import generator_system from "../rigSteps/generator-system";
  import diesel_engine from "../rigSteps/diesel-engine";
  import air_compressor_electrical_driven from "../rigSteps/air-compressor-electrical-driven";
  import air_compressor_diesel_driven from "../rigSteps/air-compressor-diesel-driven";
  import mast from "../rigSteps/mast";
  import casing_stabbing_board from "../rigSteps/casing-stabbing-board";
  import substructure from "../rigSteps/substructure";
  import crown_block from "../rigSteps/crown-block";
  import traveling_block from "../rigSteps/traveling-block";
  import hook_block from "../rigSteps/hook-block";
  import draw_works from "../rigSteps/draw-works";
  import draw_works_auxiliary_brake from "../rigSteps/draw-works-auxiliary-brake";
  import dead_line_anchor from "../rigSteps/dead-line-anchor";
  import drilling_line from "../rigSteps/drilling-line";
  import top_drive_assembly from "../rigSteps/top-drive-assembly";
  import rotary_table from "../rigSteps/rotary-table";
  import mud_pumps from "../rigSteps/mud-pumps";
  import bop_diverter from "../rigSteps/bop-diverter";
  import bop_system from "../rigSteps/bop-system";
  import accumulator_unit from "../rigSteps/accumulator-unit";

  export default {
    name: "add",
    components: {
      Breadcrumb,
      HorizontalStepper,
      general_data,
      diesel_engine,
      air_compressor_electrical_driven,
      air_compressor_diesel_driven,
      casing_stabbing_board,
      substructure,
      crown_block,
      traveling_block,
      hook_block,
      draw_works,
      draw_works_auxiliary_brake,
      dead_line_anchor,
      drilling_line,
      top_drive_assembly,
      rotary_table,
      mud_pumps,
      bop_diverter,
      bop_system,
      accumulator_unit
    },
    data() {
      return {
        rigData: {},
        steps: [
          {
            name: "first",
            title: "General Data",
            icon: "insert_drive_file",
            component: general_data
          },
          {
            name: "second",
            title: "Generator System",
            icon: "insert_drive_file",
            component: generator_system
          },
          {
            name: "third",
            title: "Diesel Engine",
            icon: "insert_drive_file",
            component: diesel_engine
          },
          {
            name: "forth",
            title: "Air Compressor Electrical Driven",
            icon: "insert_drive_file",
            component: air_compressor_electrical_driven
          },
          {
            name: "fifth",
            title: "Air Compressor Diesel Driven",
            icon: "insert_drive_file",
            component: air_compressor_diesel_driven
          },
          {
            name: "sixth",
            title: "Mast",
            icon: "insert_drive_file",
            component: mast
          },
          {
            name: "seventh",
            title: "Casing Stepping Board",
            icon: "insert_drive_file",
            component: casing_stabbing_board
          },
          {
            name: "eighth",
            title: "Substructure",
            icon: "insert_drive_file",
            component: substructure
          },
          {
            name: "ninth",
            title: "Crown Block",
            icon: "insert_drive_file",
            component: crown_block
          },
          {
            name: "tenth",
            title: "Crown Block",
            icon: "insert_drive_file",
            component: traveling_block
          },
          {
            name: "eleventh",
            title: "Crown Block",
            icon: "insert_drive_file",
            component: hook_block
          },
          {
            name: "twelfth",
            title: "Draw Works",
            icon: "insert_drive_file",
            component: draw_works
          },
          {
            name: "thirteenth",
            title: "Draw Works Auxiliary Brake",
            icon: "insert_drive_file",
            component: draw_works_auxiliary_brake
          },
          {
            name: "fourteenth",
            title: "Dead Line Anchor",
            icon: "insert_drive_file",
            component: dead_line_anchor
          },
          {
            name: "fifteenth",
            title: "Drilling Line",
            icon: "insert_drive_file",
            component: drilling_line
          },
          {
            name: "sixteenth",
            title: "Top Drive Assembly",
            icon: "insert_drive_file",
            component: top_drive_assembly
          },
          {
            name: "seventeenth",
            title: "Rotary Table",
            icon: "insert_drive_file",
            component: rotary_table
          },
          {
            name: "eighteenth",
            title: "Mud Pumps",
            icon: "insert_drive_file",
            component: mud_pumps
          },
          {
            name: "nineteenth",
            title: "Bop Diverter",
            icon: "insert_drive_file",
            component: bop_diverter
          },
          {
            name: "twentieth",
            title: "Bop System",
            icon: "insert_drive_file",
            component: bop_system
          },
          {
            name: "twenty-first",
            title: "Accumulator Unit",
            icon: "insert_drive_file",
            component: accumulator_unit
          }
        ]
      };
    },
    methods: {
      getData() {
        this.$vs.loading();
        this.$authHTTP.get("rigs/init/" + this.$route.params.uuid)
          .then(r => {
            this.rigData = r.data;
            this.$vs.loading.close();
            /*this.$store.dispatch("auth/set_data", this.rigData)
              .then(() => {
                this.$vs.loading.close();
              })
              .catch(e => {
                console.log(e);
                this.$vs.loading.close();
              });*/
          })
          .catch(e => {
            this.$vs.loading.close();
            console.log(e);
          });
      },// Executed when @completed-step event is triggered
      completeStep(payload) {
        this.steps.forEach((step) => {
          if (step.name === payload.name) {
            step.completed = true;
          }
        });
      },
      // Executed when @active-step event is triggered
      isStepActive(payload) {
        this.steps.forEach((step) => {
          if (step.name === payload.name) {
            if (step.completed === true) {
              step.completed = false;
            }
          }
        });
      },
      // Executed when @stepper-finished event is triggered
      confirmSave() {
        this.$vs.dialog({
          type:'confirm',
          color: 'danger',
          title: `Confirm`,
          text: 'Are you sure you want to save data?',
          accept:this.saveData
        })
      },
      saveData(){
        this.$vs.loading();
        this.$authHTTP.post("rigs/save-complete-rig", this.rigData)
        .then(r=>{
          this.$vs.loading.close()
          this.$router.go(-1);
        })
        .catch(e=>{
          console.log(e);
          this.$vs.loading.close()
        })
      }
    },
    mounted() {
      this.getData();
    }
  };
</script>

<style scoped lang="scss">
/*.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999999999;
  overflow: auto;
}*/
</style>
