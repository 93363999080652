function getPermissions() {
  const permission = JSON.parse(localStorage.getItem("user_permission"));
  if(permission){
    return require(`./${permission}`).default;
  }
  return require(`./default`).default;
}



export default getPermissions();
