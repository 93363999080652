  export default {
  home: {
    route: true,
  },
  rigs: {
    route: true,
    show: true,
    add: true,
    edit: true,
    delete: true,
    tabs:{
      specifications: {
        show: true,
        edit: true,
      },
      inspection: {
        show: true,
        preliminary: {
          list: true,
          add:true,
          show: true,
          edit: true,
          delete: true,
          download: true
        },
        closing: {
          list: true,
          show: true,
          edit: true
        },
        final: {
          list: true,
          show: true,
          edit: true,
          download: true,
          files :{
            delete: true,
            download: true,
          }
        },
      },
      audit: {
        show: true,
        preliminary: {
          list: true,
          add:true,
          show: true,
          edit: true,
          download: true
        },
        closing: {
          list: true,
          show: true,
          edit: true
        },
        final: {
          list: true,
          show: true,
          edit: true,
          download: true,
          files :{
            delete: true,
            download: true,
          }
        },
      },
      acceptance: {
        show: true,
        list:{
          show: true,
          add: true,
          edit: true,
          download: true
        }
      },
      stopCard: {
        show: true
      },
      qshe: {
        show: true,
        list:{
          show: true
        }
      },
      moveDrop: {
        show: true,
        move: {
          list: true,
          add:true,
          show: true,
          edit: true,
          download: true,
          files:{
            delete: true,
            download: true
          }
        },
        drop:{
          list: true,
          add: true,
          show: true,
          edit: true,
          download: true,
          files:{
            delete: true,
            download: true
          }
        }
      }
    },
  },
  visits: {
    route: true,
    showDay: true,
    show: true,
    add: true,
    edit: true,
    delete: true
  },
  clients: {
    route: true,
    add: true,
    edit: true,
    delete: true
  },
  users: {
    route: true,
    add: true,
    edit: true,
    delete: true
  },
}
