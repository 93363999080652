import AuthAPI from "../../http/auth";
import router from "@/router";

const api = new AuthAPI();

export default {
    async login({ commit }, payload) {
        try {
            let { access_token, token_type, expires_in, user_name, user_permission, client_code, owner } = await api.login(payload);
            expires_in = (expires_in * 1000) + new Date().getTime();
            commit("SET_USER", { access_token, token_type, expires_in, user_name, user_permission, client_code, owner });
          window.location.reload(true);
        } catch (e) {
            throw e;
        }
    },
    logout({commit}, router){
    this._vm.$vs.dialog({
      color: "danger",
      type: "confirm",
      title: "Logout",
      text: "Are you sure you want to logout?",
      accept: async () => {
        await api.logout();
        commit("LOGOUT");
        window.location.reload(true);
      }
    });
  },
  async forceLogout({commit}, router){
    await api.logout();
    commit("LOGOUT");
    window.location.reload(true);
  },
  set_data({commit}, payload){
      commit("SET_TEMP_DATA", payload);
  },
  user_permissions({commit}, payload){
      commit("SET_PERMISSIONS", payload);
  }
};
