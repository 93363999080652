// axios
import axios from "axios";

const baseURL = process.env.VUE_APP_BASE_URL;

export const noAuthHTTP = axios.create({
    baseURL,
    headers: {
        "Content-Type": "application/json"
    }
});

export const authHTTP = axios.create({
    baseURL,
    headers: {
        "Content-Type": "application/json",
    }
});
