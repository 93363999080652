import XLSX from "xlsx";
import XLS from "xlsx";

let file = null;
const alphabet = "ABCDEFGHIGKLMNOPQRSTUVWXYZ";
const processedSheets = [];

onmessage = function(e){
  const isXLSX = e.data.isXLSX;
  const data = e.data.file;
  if (isXLSX) {
    file = XLSX.read(data, { type: "array", blankrows: true, row: true, sheetStubs: true });
  } else {
    file = XLS.read(data, { type: "binary", blankrows: true, row: true, sheetStubs: true });
  }
  file.SheetNames.forEach(name => processSheet(name));
  postMessage(processedSheets);
}

function processSheet(sheetName){
  const sheet = file.Sheets[sheetName];
  const cells = getFormattedCells(sheet);
  const pSheet = getProcessedSheet(sheetName, cells);
  removeUnnecessarilyRows(pSheet);
  addImageColumn(pSheet, "Initial Picture");
  attachRowPriority(pSheet);
  attachRowStatus(pSheet);
  processedSheets.push(pSheet);
}

function getFormattedCells(sheet) {
  const cells = [];
  for (const cell in sheet) {
    if (sheet.hasOwnProperty(cell) && /^[a-n]\d+/i.test(cell)) {
      cells.push({
        position: cell,
        content: sheet[cell].w
      });
    }
  }
  return cells;
}

function getProcessedSheet(sheetName, cells) {
  const pSheet = {
    name: sheetName,
    rows: []
  };
  for (const currentCell of cells) {
    const index = +currentCell.position.match(/\d+/g)[0];
    if (!pSheet.rows[index]) {
      pSheet.rows[index] = [];
    }
    pSheet.rows[index].push(currentCell);
  }
  return pSheet;
}

function removeUnnecessarilyRows(processedSheet) {
  let count = 0;
  if(/summary/i.test(processedSheet.name)){
    count = 2
  }else {
    count = 9
  }
  for (let i = 0; i < count; i++) {
    processedSheet.rows.shift();
  }
}

function addImageColumn(processedSheet, columnName) {
  let headerLength = 0;
  for (let i = 0; i < processedSheet.rows.length; i++) {
    if (processedSheet.rows[i]) {
      if (processedSheet.rows[i][0]) {
        if (processedSheet.rows[i][0].content) {
          if (processedSheet.rows[i][0].content.match(/no/i)) {
            headerLength = processedSheet.rows[i].length;
            processedSheet.rows[i].push({
              position: "",
              content: columnName
            });
          } else {
            processedSheet.rows[i][headerLength] = {
              position: "",
              content: ""
            };
          }
        } else if (!processedSheet.rows[i][0].content) {
          for (let j = processedSheet.rows[i].length; j <= headerLength; j++) {
            if (j !== headerLength) {
              processedSheet.rows[i].push({
                position: "",
                content: ""
              });
            } else {
              const rowNumber = processedSheet.rows[i][0].position.match(/\d+/)[0];
              const columnLetter = alphabet[processedSheet.rows[i].length];
              processedSheet.rows[i][headerLength] = {
                position: columnLetter + rowNumber,
                content: "",
                type: "image"
              };
            }
          }
        }
      }
    }
  }
}

function attachRowPriority(processedSheet) {
  const rows = processedSheet.rows;
  processedSheet.rowsPriority = [];
  parent:
    for (const row of rows) {
      if (row) {
        for (const cell of row) {
          if (cell && cell.content && cell.position) {
            if (/^e\d+/i.test(cell.position)) {
              if (cell.content.match(/critical/i)) {
                processedSheet.rowsPriority.push("critical");
                continue parent;
              } else if (cell.content.match(/minor/i)) {
                processedSheet.rowsPriority.push("minor");
                continue parent;
              } else if (cell.content.match(/major/i)) {
                processedSheet.rowsPriority.push("major");
                continue parent;
              }
            }
          }
        }
        processedSheet.rowsPriority.push("none");
      } else {
        processedSheet.rowsPriority.push("none");
      }
    }
}

function attachRowStatus(processedSheet) {
  const rows = processedSheet.rows;
  processedSheet.rowsStatus = [];
  for (const row of rows) {
    if (row) {
      const closed = row.find(cell => {
        if (cell && cell.content && cell.position) {
          return cell.content.match(/closed/i) && /^g\d+/i.test(cell.position);
        }
        return false;
      });
      if (closed) {
        processedSheet.rowsStatus.push("closed");
        continue;
      }

      const progress = row.find(cell => {
        if (cell && cell.content) {
          return cell.content.match(/progress/i) && /^g\d+/i.test(cell.position);
        }
        return false;
      });
      if (progress) {
        processedSheet.rowsStatus.push("progress");
        continue;
      }

      const open = row.find(cell => {
        if (cell && cell.content) {
          return cell.content.match(/open/i) && /^g\d+/i.test(cell.position);
        }
        return false;
      });

      if (open) {
        processedSheet.rowsStatus.push("open");
      } else {
        processedSheet.rowsStatus.push("none");
      }

    } else {
      processedSheet.rowsStatus.push("none");
    }
  }
}
