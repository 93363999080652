<template>
  <vs-row>
    <vs-col vs-w="12">
      <Breadcrumb></Breadcrumb>
    </vs-col>
    <vs-col vs-w="5" vs-offset="7">
      <vs-select label="Sheets" v-model="selectedSheet" placeholder="Choose zone"
                 class="w-100">
        <vs-select-item v-for="(sheet, index) in processedSheets" :key="index" :value="sheet.name"
                        :text="sheet.name"></vs-select-item>
      </vs-select>
    </vs-col>
    <vs-row>
      <vs-col vs-w="12" v-if="selectedSheet && !/summary/i.test(selectedSheet)" style="overflow: auto">
        <table id="details" class="print">
          <tbody>
          <tr v-for="(row, index) in currentSheet.rows" :key="index" :class="getRowColor(row)">
            <td v-for="(cell, index) in row" :key="index" :class="getCellColor(cell)" :style="{'min-width' : (cell.type === 'image' ? '120px' : '90px' )}">
              <span
                v-if="cell && /^g\d+/i.test(cell.position) && row.findIndex(x=> x && x.type && x.type === 'image') > -1 && (!cell.type || cell.type !== 'image')">
                <vs-select v-model="cell.content" placeholder="Choose Status"
                           style="min-width: 100px !important;"
                           class="w-100" @change="attachClosingDate(cell, row)">
        <vs-select-item v-for="(status, index) in statuses" :key="index" :value="status"
                        :text="status"></vs-select-item>
      </vs-select>
              </span>
              <span v-else-if="cell && (!cell.type || cell.type !== 'image')">{{cell.content}}</span>
              <span v-else-if="cell && cell.type && cell.type === 'image' && !cell.content && index === row.length - 1">
                <vs-input label="Image" type="file"
                          accept="image/*"
                          class="w-100"
                          @change="uploadImage(currentSheet.name,row, $event)"></vs-input>
              </span>
              <span v-else-if="cell && cell.type && cell.type === 'image' && cell.content && index === row.length - 1">
                <img :key="updateImage" :src="getImagePath(cell.content)" alt="Report Image" width="50" height="50"
                     @click="viewImage(cell.content, row)">
                 <vs-input label="Update Picture" type="file"
                           v-if="index === row.length -1"
                           accept="image/*"
                           class="w-100"
                           @change="uploadImage(currentSheet.name,row, $event)"></vs-input>
                </span>
              <span v-else-if="cell && cell.type && cell.type === 'image' && cell.content && index !== row.length - 1">
                <img :key="updateImage" :src="getImagePath(cell.content)" alt="Report Image" width="50" height="50"
                     @click="viewImage(cell.content, row)">
              </span>
            </td>
          </tr>
          </tbody>
        </table>
        <vs-row>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center">
            <vs-button @click="$router.go(-1)" style="margin-right: 10px;">
              <font-awesome-icon icon="arrow-left"></font-awesome-icon>&nbsp;Back
            </vs-button>
            <vs-button @click="confirmUpdate">
              <font-awesome-icon icon="save"></font-awesome-icon>&nbsp;Save
            </vs-button>
          </vs-col>
        </vs-row>
        <vs-popup fullscreen :title="displayImageTitle" :active.sync="viewImagePopup" class="popup">
          <div>
            <img :src="getImagePath(displayImageURL)" alt="Report Image">
          </div>
        </vs-popup>
      </vs-col>
      <vs-col vs-w="6" vs-offset="3"  v-else-if="selectedSheet && /summary/i.test(selectedSheet)">
        <chart :chart-data="getChartData()"></chart>
      </vs-col>
    </vs-row>
  </vs-row>
</template>

<script>
  import XLSX from "xlsx";
  import XLS from "xlsx";
  import Chart from "../../../../../layouts/components/Chart";
  import Breadcrumb from "../../../../../layouts/components/Breadcrumb";

  export default {
    name: "closing-edit",
    components: { Breadcrumb, Chart },
    data() {
      return {
        file: null,
        reportUUID: "",
        processedSheets: [],
        rigData: {},
        parentReportUUID: "",
        parentReport: [],
        selectedSheet: "",
        updateImage: 0,
        viewImagePopup: false,
        displayImageURL: "",
        displayImageTitle: "",
        alphabet: "ABCDEFGHIGKLMNOPQRSTUVWXYZ",
        statuses: ["OPEN", "CLOSED", "PROGRESS"]
      };
    },
    methods: {
      getChartData(){
        const sheet = this.processedSheets.find(sheet =>{
          return /summary/i.test(sheet.name);
        });
        let criticalOpen = 0;
        let criticalClosed = 0;
        let majorOpen = 0;
        let majorClosed = 0;
        let minorOpen = 0;
        let minorClosed = 0;
        const row15 = sheet.rows.find(row =>{
          if(row){
            if(row[0]){
              return /^[a-z]15$/i.test(row[0].position);
            }
          }
          return false;
        });
        const row16 = sheet.rows.find(row =>{
          if(row){
            if(row[0]){
              return /^[a-z]16$/i.test(row[0].position);
            }
          }
          return false;
        });
        console.log({row15, row16});
        const B15 = row15.find(cell=>{
          if(cell){
            return /^b15$/i.test(cell.position);
          }
        });
        criticalOpen = +B15.content;

        const B16 = row16.find(cell=>{
          if(cell){
            return /^b16$/i.test(cell.position);
          }
        });
        criticalClosed = +B16.content;

        const C15 = row15.find(cell=>{
          if(cell){
            return /^c15$/i.test(cell.position);
          }
        });
        majorOpen = +C15.content;

        const C16 = row16.find(cell=>{
          if(cell){
            return /^c16$/i.test(cell.position);
          }
        });
        majorClosed = +C16.content;

        const D15 = row15.find(cell=>{
          if(cell){
            return /^d15$/i.test(cell.position);
          }
        });
        minorOpen = +D15.content;

        const D16 = row16.find(cell=>{
          if(cell){
            return /^d16$/i.test(cell.position);
          }
        });
        minorClosed = +D16.content;

        return {
          labels: ["Critical", "Major", "Minor"],
          datasets: [
            {
              label: 'Open',
              backgroundColor: 'blue',
              data: [criticalOpen, majorOpen, minorOpen]
            }, {
              label: 'Closed',
              backgroundColor: 'green',
              data: [criticalClosed, majorClosed, minorClosed]
            }
          ]
        }
      },
      confirmUpdate(){
        this.$vs.dialog({
          color: "primary",
          type: "confirm",
          title: "Update Report",
          text: "Are you sure you want to Update this report?",
          accept: this.updateReport
        });
      },
      updateReport() {
        this.$vs.loading();
        for (const sheet of this.processedSheets){
          this.attachRowStatus(sheet);
          this.attachRowPriority(sheet);
        }
        const payload = {
          "reportUUID": this.$route.params.uuid,
          "data": JSON.stringify(this.processedSheets),
          "Critical Open": this.overAllCriticalOpen,
          "Critical In Progress": this.overAllCriticalProgress,
          "Major Open": this.overAllMajorOpen,
          "Major In Progress": this.overAllMajorProgress,
          "Minor Open": this.overAllMinorOpen,
          "Minor In Progress": this.overAllMinorProgress,
          "Overall Open": this.overAllPrioritiesOpen,
          "Overall In Progress": this.overAllPrioritiesProgress
        };
        this.$authHTTP.post("reports/closing/update-self", payload)
          .then(() => {
          })
          .catch(e => {
            console.log(e);
          })
          .finally(() => {
            const qshe = {
              "parentUUID": this.parentReportUUID,
              "data": JSON.stringify(this.QSHEReport),
              "Critical Open": this.QSHECriticalOpen,
              "Critical In Progress": this.QSHECriticalProgress,
              "Major Open": this.QSHEMajorOpen,
              "Major In Progress": this.QSHEMajorProgress,
              "Minor Open": this.QSHEMinorOpen,
              "Minor In Progress": this.QSHEMinorProgress,
              "Overall Open": this.QSHEPrioritiesOpen,
              "Overall In Progress": this.QSHEPrioritiesProgress
            }
            this.$authHTTP.post("reports/qshe/update-parent", qshe)
            .then(()=>{
              this.$router.go(-1);
            })
            .catch(e=>{
              console.log(e);
            })
            .finally(()=>{
              this.$vs.loading.close();
            })
          });
      },
      uploadImage(sheetName, row, $event) {
        this.$vs.loading();
        const formData = new FormData();
        formData.append("row", row[0].position.match(/\d+/i)[0]);
        formData.append("image", $event.target.files[0]);
        formData.append("rigUUID", this.rigData.UUID);
        formData.append("reportUUID", this.$route.params.uuid);
        formData.append("sheetName", sheetName);
        this.$authHTTP.post("reports/closing/upload/image", formData)
          .then(r => {
            row[row.length - 1].content = r.data;
            this.$vs.notify({ color: "success", title: "Upload Success", text: "Image is uploaded successfully." });
          })
          .catch(e => {
            console.log(e);
            this.$vs.notify({
              color: "danger",
              title: "Upload Failed",
              text: "Error is happened while uploading image"
            });
          })
          .finally(() => {
            this.$vs.loading.close();
            this.updateImage++;
          });
      },
      getRowColor(row) {
        if (row && row.length > 0 && row[0].content) {
          if (row[0].content.match(/(no)+/i)) {
            return "table-header";
          }
          if (row[0].content.match(/^\d+$/i)) {
            return "table-title";
          }
          if (row[0].content.match(/^\d+\.\d+$/i)) {
            return "table-subtitle";
          }
        }
      },
      getCellColor(cell) {
        if (cell && cell.content && cell.position && /^[eg]\d+/i.test(cell.position)) {
          if (cell.content.match(/critical/i)) {
            return "critical";
          }
          if (cell.content.match(/minor/i)) {
            return "minor";
          }
          if (cell.content.match(/major/i)) {
            return "major";
          }
          if (cell.content.match(/open/i)) {
            return "open";
          }
          if (cell.content.match(/closed/i)) {
            return "closed";
          }
          if (cell.content.match(/progress/i)) {
            return "progress";
          }
        }
      },
      getImagePath(data) {
        if (data) {
          return process.env.VUE_APP_BASE_URL.split("api")[0] + data;
        }
        return process.env.VUE_APP_BASE_URL.split("api")[0] + "uploads/images/logo.png";
      },
      viewImage(url, row) {
        this.displayImageURL = url;
        this.displayImageTitle = row[2].content;
        this.viewImagePopup = true;
      },
      getData() {
        this.$vs.loading();
        this.$authHTTP.get("reports/closing/" + this.$route.params.uuid)
          .then(r => {
            this.processedSheets = JSON.parse(r.data.data);
            this.processedSheets = this.processedSheets.filter(sheet=>{
              return !/summary/i.test(sheet.name);
            });
            this.rigData = r.data.rig;
            this.parentReportUUID = r.data.parentUUID;
            this.getParentReport(this.parentReportUUID);
          })
          .catch(e => {
            console.log(e);
          })
          .finally(() => {
            this.$vs.loading.close();
          });
      },
      getParentReport(uuid){
        this.$vs.loading();
        this.$authHTTP.get("reports/inspection/" + uuid)
          .then(r=>{
            this.parentReport = JSON.parse(r.data.data);
          })
          .catch(e=>{
            console.log(e);
          })
          .finally(()=>{
            this.$vs.loading.close();
          });
      },
      addImageColumn(processedSheet, columnName) {
        let headerLength = 0;
        for (let i = 0; i < processedSheet.rows.length; i++) {
          if (processedSheet.rows[i]) {
            if (processedSheet.rows[i][0]) {
              if (processedSheet.rows[i][0].content) {
                if (processedSheet.rows[i][0].content.match(/no/i)) {
                  headerLength = processedSheet.rows[i].length;
                  processedSheet.rows[i].push({
                    position: "",
                    content: columnName
                  });
                } else {
                  processedSheet.rows[i][headerLength] = {
                    position: "",
                    content: ""
                  };
                }
              } else if (!processedSheet.rows[i][0].content) {
                for (let j = processedSheet.rows[i].length; j <= headerLength; j++) {
                  if (j !== headerLength) {
                    processedSheet.rows[i].push({
                      position: "",
                      content: ""
                    });
                  } else {
                    const rowNumber = processedSheet.rows[i][0].position.match(/\d+/)[0];
                    const columnLetter = this.alphabet[processedSheet.rows[i].length];
                    processedSheet.rows[i][headerLength] = {
                      position: columnLetter + rowNumber,
                      content: "",
                      type: "image"
                    };
                  }
                }
              }
            }
          }
        }
      },
      attachRowPriority(processedSheet) {
        const rows = processedSheet.rows;
        processedSheet.rowsPriority = [];
        parent:
          for (const row of rows) {
            if (row) {
              for (const cell of row) {
                if (cell && cell.content && cell.position) {
                  if(/^e\d+/i.test(cell.position)){
                    if (cell.content.match(/critical/i)) {
                      processedSheet.rowsPriority.push("critical");
                      continue parent;
                    } else if (cell.content.match(/minor/i)) {
                      processedSheet.rowsPriority.push("minor");
                      continue parent;
                    } else if (cell.content.match(/major/i)) {
                      processedSheet.rowsPriority.push("major");
                      continue parent;
                    }
                  }
                }
              }
              processedSheet.rowsPriority.push("none");
            } else {
              processedSheet.rowsPriority.push("none");
            }
          }
      },
      attachRowStatus(processedSheet) {
        const rows = processedSheet.rows;
        processedSheet.rowsStatus = [];
        for (const row of rows) {
          if (row) {
            const closed = row.find(cell => {
              if (cell && cell.content && cell.position) {
                return cell.content.match(/closed/i) && /^g\d+/i.test(cell.position);
              }
              return false;
            });
            if (closed) {
              processedSheet.rowsStatus.push("closed");
              continue;
            }

            const progress = row.find(cell => {
              if (cell && cell.content) {
                return cell.content.match(/progress/i) && /^g\d+/i.test(cell.position);
              }
              return false;
            });
            if (progress) {
              processedSheet.rowsStatus.push("progress");
              continue;
            }

            const open = row.find(cell => {
              if (cell && cell.content) {
                return cell.content.match(/open/i) && /^g\d+/i.test(cell.position);
              }
              return false;
            });

            if (open) {
              processedSheet.rowsStatus.push("open");
            } else {
              processedSheet.rowsStatus.push("none");
            }

          } else {
            processedSheet.rowsStatus.push("none");
          }
        }
      },
      attachClosingDate(cell, row){
        const closingDateCell = row.find(cell=>{
          if(cell && cell.position){
            return /^i/i.test(cell.position);
          }
          return false;
        });
        if(/^closed$/i.test(cell.content)){
          closingDateCell.content = this.$moment().format("D-MMM-YYYY");
        }else {
          closingDateCell.content = "";
        }
      }
    },
    computed: {
      currentSheet() {
        const selectedSheet = this.processedSheets.find(x => {
          return x.name === this.selectedSheet;
        });
        return selectedSheet ? selectedSheet : {};
      },
      criticalCount() {
        return this.currentSheet.rowsPriority.filter(priority => {
          return priority === "critical";
        }).length;
      },
      majorCount() {
        return this.currentSheet.rowsPriority.filter(priority => {
          return priority === "major";
        }).length;
      },
      minorCount() {
        return this.currentSheet.rowsPriority.filter(priority => {
          return priority === "minor";
        }).length;
      },
      allPriorityCount() {
        return this.criticalCount + this.majorCount + this.minorCount;
      },
      criticalAndClosedCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "critical" && status === "closed") {
            count++;
          }
        }
        return count;
      },
      criticalAndInProgressCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "critical" && status === "progress") {
            count++;
          }
        }
        return count;
      },
      criticalAndOpenCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "critical" && status === "open") {
            count++;
          }
        }
        return count;
      },
      majorAndClosedCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "major" && status === "closed") {
            count++;
          }
        }
        return count;
      },
      majorAndInProgressCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "major" && status === "progress") {
            count++;
          }
        }
        return count;
      },
      majorAndOpenCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "major" && status === "open") {
            count++;
          }
        }
        return count;
      },
      minorAndClosedCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "minor" && status === "closed") {
            count++;
          }
        }
        return count;
      },
      minorAndInProgressCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "minor" && status === "progress") {
            count++;
          }
        }
        return count;
      },
      minorAndOpenCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "minor" && status === "open") {
            count++;
          }
        }
        return count;
      },
      allOpenStatus() {
        return this.criticalAndOpenCount + this.majorAndOpenCount + this.minorAndOpenCount;
      },
      allInProgressStatus() {
        return this.criticalAndInProgressCount + this.majorAndInProgressCount + this.minorAndInProgressCount;
      },
      allClosedStatus() {
        return this.criticalAndClosedCount + this.majorAndClosedCount + this.minorAndClosedCount;
      },
      criticalClosedPercentage() {
        const percentage = Math.floor((this.criticalAndClosedCount / this.criticalCount) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      majorClosedPercentage() {
        const percentage = Math.floor((this.majorAndClosedCount / this.majorCount) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      minorClosedPercentage() {
        const percentage = Math.floor((this.minorAndClosedCount / this.minorCount) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      allClosedPercentage() {
        const percentage = Math.floor((this.allClosedStatus / this.allPriorityCount) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      overAllCriticalClosedPercentage() {
        let criticalCount = 0;
        let closedCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "critical") {
              criticalCount++;
            }
            if (priority === "critical" && status === "closed") {
              closedCount++;
            }
          }
        }
        const percentage = Math.floor((closedCount / criticalCount) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      overAllMajorClosedPercentage() {
        let majorCount = 0;
        let closedCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "major") {
              majorCount++;
            }
            if (priority === "major" && status === "closed") {
              closedCount++;
            }
          }
        }
        const percentage = Math.floor((closedCount / majorCount) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      overAllMinorClosedPercentage() {
        let minorCount = 0;
        let closedCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "minor") {
              minorCount++;
            }
            if (priority === "minor" && status === "closed") {
              closedCount++;
            }
          }
        }
        const percentage = Math.floor((closedCount / minorCount) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      overAllPrioritiesClosedPercentage() {
        let count = 0;
        let closedCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "minor" || priority === "major" || priority === "critical") {
              count++;
            }
            if ((priority === "minor" || priority === "major" || priority === "critical") && status === "closed") {
              closedCount++;
            }
          }
        }
        const percentage = Math.floor((closedCount / count) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      QSHEReport(){
        const qsheOriginal = this.parentReport.find(s => /qshe/ig.test(s.name));
        const qsheUpdated = this.processedSheets.find(s => /qshe/ig.test(s.name));
        const clone = JSON.parse(JSON.stringify(qsheOriginal));
        this.addImageColumn(clone, "Final Picture");
        for (const rowOriginal of clone.rows){
          for(const rowUpdated of qsheUpdated.rows){
            if(rowOriginal && rowUpdated){
              for(const cellOriginal of rowOriginal){
                for(const cellUpdated of rowUpdated){
                  if(cellOriginal && cellUpdated && cellOriginal.position && cellUpdated.position){
                    if(cellOriginal.position === cellUpdated.position){
                      cellOriginal.content = cellUpdated.content;
                    }
                  }
                }
              }
            }
          }
        }
        this.attachRowPriority(clone);
        this.attachRowStatus(clone);
        return clone;
      },
      QSHECriticalOpen() {
        let openCount = 0;
        const sheet = this.QSHEReport;
        for (const i in sheet.rowsPriority) {
          const priority = sheet.rowsPriority[i];
          const status = sheet.rowsStatus[i];
          if (priority === "critical" && status === "open") {
            openCount++;
          }
        }

        return openCount;
      },
      QSHECriticalProgress() {
        let inProgressCount = 0;
        const sheet = this.QSHEReport;
        for (const i in sheet.rowsPriority) {
          const priority = sheet.rowsPriority[i];
          const status = sheet.rowsStatus[i];
          if (priority === "critical" && status === "progress") {
            inProgressCount++;
          }
        }
        return inProgressCount;
      },
      QSHEMajorOpen() {
        let openCount = 0;
        const sheet = this.QSHEReport;
        for (const i in sheet.rowsPriority) {
          const priority = sheet.rowsPriority[i];
          const status = sheet.rowsStatus[i];
          if (priority === "major" && status === "open") {
            openCount++;
          }
        }
        return openCount;
      },
      QSHEMajorProgress() {
        let inProgressCount = 0;
        const sheet = this.QSHEReport;
        for (const i in sheet.rowsPriority) {
          const priority = sheet.rowsPriority[i];
          const status = sheet.rowsStatus[i];
          if (priority === "major" && status === "progress") {
            inProgressCount++;
          }
        }
        return inProgressCount;
      },
      QSHEMinorOpen() {
        let openCount = 0;
        const sheet = this.QSHEReport;
        for (const i in sheet.rowsPriority) {
          const priority = sheet.rowsPriority[i];
          const status = sheet.rowsStatus[i];
          if (priority === "minor" && status === "open") {
            openCount++;
          }
        }
        return openCount;
      },
      QSHEMinorProgress() {
        let inProgressCount = 0;
        const sheet = this.QSHEReport;
        for (const i in sheet.rowsPriority) {
          const priority = sheet.rowsPriority[i];
          const status = sheet.rowsStatus[i];
          if (priority === "minor" && status === "progress") {
            inProgressCount++;
          }
        }
        return inProgressCount;
      },
      QSHEPrioritiesOpen() {
        let openCount = 0;
        const sheet = this.QSHEReport;
        for (const i in sheet.rowsPriority) {
          const priority = sheet.rowsPriority[i];
          const status = sheet.rowsStatus[i];
          if ((priority === "minor" || priority === "major" || priority === "critical") && status === "open") {
            openCount++;
          }
        }
        return openCount;
      },
      QSHEPrioritiesProgress() {
        let inProgressCount = 0;
        const sheet = this.QSHEReport;
        for (const i in sheet.rowsPriority) {
          const priority = sheet.rowsPriority[i];
          const status = sheet.rowsStatus[i];
          if ((priority === "minor" || priority === "major" || priority === "critical") && status === "progress") {
            inProgressCount++;
          }
        }
        return inProgressCount;
      },
      overAllCriticalOpen() {
        let openCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "critical" && status === "open") {
              openCount++;
            }
          }
        }
        return openCount;
      },
      overAllCriticalProgress() {
        let inProgressCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "critical" && status === "progress") {
              inProgressCount++;
            }
          }
        }
        return inProgressCount;
      },
      overAllMajorOpen() {
        let openCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "major" && status === "open") {
              openCount++;
            }
          }
        }
        return openCount;
      },
      overAllMajorProgress() {
        let inProgressCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "major" && status === "progress") {
              inProgressCount++;
            }
          }
        }
        return inProgressCount;
      },
      overAllMinorOpen() {
        let openCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "minor" && status === "open") {
              openCount++;
            }
          }
        }
        return openCount;
      },
      overAllMinorProgress() {
        let inProgressCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "minor" && status === "progress") {
              inProgressCount++;
            }
          }
        }
        return inProgressCount;
      },
      overAllPrioritiesOpen() {
        let openCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if ((priority === "minor" || priority === "major" || priority === "critical") && status === "open") {
              openCount++;
            }
          }
        }
        return openCount;
      },
      overAllPrioritiesProgress() {
        let inProgressCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if ((priority === "minor" || priority === "major" || priority === "critical") && status === "progress") {
              inProgressCount++;
            }
          }
        }
        return inProgressCount;
      },
    },
    mounted() {
      this.getData();
    }
  };
</script>

<style lang="scss" scoped>
  table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    color: #000;

    img {
      cursor: pointer;
      margin-top: 10px;
    }
  }

  table, th, td {
    border: 1px solid rgba(0, 0, 0, 0.5);
    text-align: center;
  }

  td, th {
    padding: 5px;
    span {
      display: block;
    }
  }

  .w-100 {
    width: 100% !important;
  }

  .table-header {
    background-color: blue;
    color: #fff;
    font-size: 1.1rem;
    font-weight: bold;
  }

  .table-title {
    background-color: #000;
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
  }

  .table-subtitle {
    background-color: #ccc;
    font-weight: bold;
    font-size: 1rem;
  }

  .critical {
    color: red;
    font-weight: bold;
  }

  .minor {
    color: yellow;
    font-weight: bold;
  }

  .major {
    color: blue;
    font-weight: bold;
  }

  .open {
    background-color: red;
    font-weight: bold;
  }

  .closed {
    background-color: green;
    font-weight: bold;
  }

  .progress {
    background-color: orangered;
    font-weight: bold;
  }

  #summary, #details {
    margin: 10px 0;
  }

  .popup {
    div {
      width: 100%;
      text-align: center;

      img {
        max-width: 100%;
        height: auto;
      }
    }

  }

  .stripe {
    background-color: #ccc;
  }
</style>
