<template>
  <vs-row>
    <vs-col vs-w="12" vs-type="flex" vs-justify="flex-end" vs-align="center">
      <vs-button v-if="permissions.add" @click="$router.push('/client/add/')">
        <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Add
      </vs-button>
    </vs-col>
    <vs-col vs-w="12">
      <vs-table search stripe :max-items="list.per_page" :data="list.data">
        <template slot="header">
          <h3>
            Clients
          </h3>
        </template>
        <template slot="thead">
          <vs-th>
            No.
          </vs-th>
          <vs-th>
            Name
          </vs-th>
          <vs-th>
            Code
          </vs-th>
          <vs-th>
            Actions
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="itemOrder(tr)">
              {{ ((list.current_page - 1) * list.per_page) + itemOrder(tr) }}
            </vs-td>
            <vs-td :data="tr.name">
              {{tr.name}}
            </vs-td>

            <vs-td :data="tr.code">
              {{tr.code}}
            </vs-td>
            <vs-td :data="tr">
              <crud-actions
                :id="tr.UUID"
                :permissions="permissions"
                @editClicked="editItem"
                @trashClicked="confirmDelete"
              ></crud-actions>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <Pagination url="/rigs/list" :list-data="list" @data-changed="list = $event"></Pagination>
    </vs-col>
  </vs-row>
</template>

<script>
  import Breadcrumb from "../../../layouts/components/Breadcrumb";
  import Pagination from "../../../layouts/components/Pagination";
  import CrudActions from "../../../layouts/components/CrudActions";
    export default {
        name: "list",
      components:{
          Breadcrumb,
        Pagination,
        CrudActions
      },
      data(){
        return {
          list: {}
        }
      },
      methods:{
        getData(){
          this.$vs.loading();
          this.$authHTTP.get("clients/list")
            .then(r => {
              this.$vs.loading.close()
              this.list = r.data;
            })
            .catch(e => {
              this.$vs.loading.close()
              console.log(e);
            });
        },
        editItem(uuid){
          this.$router.push('client/edit/' + uuid);
        },
        deleteItem(uuid){
          this.$vs.loading();
          this.$authHTTP.delete('clients/delete/' + uuid)
            .then(r => {
              const index = this.list.data.findIndex(x =>  x.UUID === uuid );
              this.list.data.splice(index, 1);
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: r.data
              });
            }).catch(e => {
            console.log(e);
            this.$vs.notify({
              title: 'Error',
              text: e.response.data,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            });
          }).finally(() => {
            this.$vs.loading.close();
          });
        },
        confirmDelete(id){
          this.$vs.dialog({
            title: "Confirm",
            text: "Are you sure that you want to delete this item?",
            color: "danger",
            acceptText: "I'm sure",
            accept: () => this.deleteItem(id),
            type: "confirm"
          });
        },
        itemOrder(data){
          const index = this.list.data.findIndex(item => {
            return item.id === data.id;
          });
          return index + 1;
        },
      },
      computed:{
        permissions(){
          return this.$store.state.auth.permissions.clients;
        }
      },
      mounted() {
          this.getData();
      }
    };
</script>

<style scoped>

</style>
