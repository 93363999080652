/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
import store from "./store/store";
import permissions from "./permissions";
import home from "./views/pages/Home";
import rigsList from "./views/pages/Rigs/list";
import rigShow from "./views/pages/Rigs/show";
import rigEdit from "./views/pages/Rigs/edit";
import rigAdd from "./views/pages/Rigs/add";
import inspectionShow from "./views/pages/Rigs/show/inspections/show";
import inspectionEdit from "./views/pages/Rigs/show/inspections/edit";
import auditShow from "./views/pages/Rigs/show/audit/show";
import acceptanceShow from "./views/pages/Rigs/show/acceptance/show";
import auditEdit from "./views/pages/Rigs/show/audit/edit";
import acceptanceEdit from "./views/pages/Rigs/show/acceptance/edit";
import closingShow from "./views/pages/Rigs/show/closing/show";
import auditClosingShow from "./views/pages/Rigs/show/audit_closing/show";
import qsheShow from "./views/pages/Rigs/show/qshe/show";
import closingEdit from "./views/pages/Rigs/show/closing/edit";
import auditClosingEdit from "./views/pages/Rigs/show/audit_closing/edit";
import finalEdit from "./views/pages/Rigs/show/final/edit";
import finalShow from "./views/pages/Rigs/show/final/show";
import auditFinalEdit from "./views/pages/Rigs/show/audit_final/edit";
import auditFinalShow from "./views/pages/Rigs/show/audit_final/show";
import reportAdd from "./views/pages/Rigs/show/inspections/add";
import auditAdd from "./views/pages/Rigs/show/audit/add";
import acceptanceAdd from "./views/pages/Rigs/show/acceptance/add";
import moveAdd from "./views/pages/Rigs/show/moveAndDrop/move/add";
import moveEdit from "./views/pages/Rigs/show/moveAndDrop/move/edit";
import moveShow from "./views/pages/Rigs/show/moveAndDrop/move/show";
import dropAdd from "./views/pages/Rigs/show/moveAndDrop/drop/add";
import dropEdit from "./views/pages/Rigs/show/moveAndDrop/drop/edit";
import dropShow from "./views/pages/Rigs/show/moveAndDrop/drop/show";
import clientsList from "./views/pages/clients/list";
import clientEdit from "./views/pages/clients/edit";
import clientAdd from "./views/pages/clients/add";
import usersList from "./views/pages/users/list";
import userEdit from "./views/pages/users/edit";
import userAdd from "./views/pages/users/add";
import visitsList from "./views/pages/visits/list";
import visitAdd from "./views/pages/visits/add";
import visitEdit from "./views/pages/visits/edit";
import visitDay from "./views/pages/visits/day";
import visitShow from "./views/pages/visits/show";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          name: "home",
          component: home,
          meta: {
            requiresAuth: true,
            enabled: permissions.home.route
          }
        },
        {
          path: "/rigs",
          name: "Rigs List",
          component: rigsList,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.route
          }
        },
        {
          path: "/rig/show/:uuid",
          name: "Rigs Show",
          component: rigShow,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.show
          }
        },
        {
          path: "/rig/edit/:uuid",
          name: "Rigs Edit",
          component: rigEdit,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.edit
          }
        },
        {
          path: "/rig/add/:uuid",
          name: "Rigs Add",
          component: rigAdd,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.add
          }
        },
        {
          path: "/inspection/show/:uuid",
          name: "Inspection Show",
          component: inspectionShow,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.inspection.preliminary.show
          }
        },
        {
          path: "/inspection/edit/:uuid",
          name: "Inspection Edit",
          component: inspectionEdit,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.inspection.preliminary.edit
          }
        },
        {
          path: "/inspection/add/:uuid",
          name: "Inspection Add",
          component: reportAdd,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.inspection.preliminary.add
          }
        },
        {
          path: "/closing/show/:uuid",
          name: "Closing Show",
          component: closingShow,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.inspection.closing.show
          }
        },
        {
          path: "/closing/edit/:uuid",
          name: "Closing Edit",
          component: closingEdit,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.inspection.closing.show
          }
        },
        {
          path: "/audit-closing/show/:uuid",
          name: "Audit Closing Show",
          component: auditClosingShow,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.audit.closing.show
          }
        },
        {
          path: "/audit-closing/edit/:uuid",
          name: "Audit Closing Edit",
          component: auditClosingEdit,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.audit.closing.show
          }
        },
        {
          path: "/qshe/show/:uuid",
          name: "QSHE Show",
          component: qsheShow,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.qshe.show
          }
        },
        {
          path: "/final/show/:uuid",
          name: "Final Show",
          component: finalShow,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.inspection.final.show
          }
        },
        {
          path: "/final/edit/:uuid",
          name: "Final Edit",
          component: finalEdit,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.inspection.final.edit
          }
        },
        {
          path: "/audit-final/show/:uuid",
          name: "Audit Final Show",
          component: auditFinalShow,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.audit.final.show
          }
        },
        {
          path: "/audit-final/edit/:uuid",
          name: "Audit Final Edit",
          component: auditFinalEdit,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.audit.final.edit
          }
        },
        {
          path: "/clients",
          name: "Clients List",
          component: clientsList,
          meta: {
            requiresAuth: true,
            enabled: permissions.clients.route
          }
        },
        {
          path: "/client/edit/:uuid",
          name: "Clients Edit",
          component: clientEdit,
          meta: {
            requiresAuth: true,
            enabled: permissions.clients.edit
          }
        },
        {
          path: "/client/add/",
          name: "Clients Add",
          component: clientAdd,
          meta: {
            requiresAuth: true,
            enabled: permissions.clients.add
          }
        },
        {
          path: "/users",
          name: "Users List",
          component: usersList,
          meta: {
            requiresAuth: true,
            enabled: permissions.users.route
          }
        },
        {
          path: "/user/edit/:uuid",
          name: "Users Edit",
          component: userEdit,
          meta: {
            requiresAuth: true,
            enabled: permissions.users.edit
          }
        },
        {
          path: "/user/add/",
          name: "Users Add",
          component: userAdd,
          meta: {
            requiresAuth: true,
            enabled: permissions.users.add
          }
        },
        {
          path: "/visits",
          name: "Visits List",
          component: visitsList,
          meta: {
            requiresAuth: true,
            enabled: permissions.visits.route
          }
        },
        {
          path: "/visit/add",
          name: "Visits Add",
          component: visitAdd,
          meta: {
            requiresAuth: true,
            enabled: permissions.visits.add
          }
        },
        {
          path: "/visit/edit/:uuid",
          name: "Visits Edit",
          component: visitEdit,
          meta: {
            requiresAuth: true,
            enabled: permissions.visits.edit
          }
        },
        {
          path: "/visit/show/:uuid",
          name: "Visits Show",
          component: visitShow,
          meta: {
            requiresAuth: true,
            enabled: permissions.visits.show
          }
        },
        {
          path: "/visit/:day/:month/:year",
          name: "Visits Day",
          component: visitDay,
          meta: {
            requiresAuth: true,
            enabled: permissions.visits.showDay
          }
        },
        {
          path: "/acceptance/show/:uuid",
          name: "Acceptance Show",
          component: acceptanceShow,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.acceptance.list.show
          }
        },
        {
          path: "/acceptance/edit/:uuid",
          name: "Acceptance Edit",
          component: acceptanceEdit,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.acceptance.list.edit
          }
        },
        {
          path: "/acceptance/add/:uuid",
          name: "Acceptance Add",
          component: acceptanceAdd,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.acceptance.list.add
          }
        },
        {
          path: "/audit/show/:uuid",
          name: "Audit Show",
          component: auditShow,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.audit.preliminary.show
          }
        },
        {
          path: "/audit/edit/:uuid",
          name: "Audit Edit",
          component: auditEdit,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.audit.preliminary.edit
          }
        },
        {
          path: "/audit/add/:uuid",
          name: "Audit Add",
          component: auditAdd,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.audit.preliminary.add
          }
        },
        {
          path: "/move/add/:uuid",
          name: "Move Add",
          component: moveAdd,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.moveDrop.move.add
          }
        },
        {
          path: "/move/edit/:uuid",
          name: "Move Edit",
          component: moveEdit,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.moveDrop.move.edit
          }
        },
        {
          path: "/move/show/:uuid",
          name: "Move Show",
          component: moveShow,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.moveDrop.move.show
          }
        },
        {
          path: "/drop/add/:uuid",
          name: "Drop Add",
          component: dropAdd,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.moveDrop.drop.add
          }
        },
        {
          path: "/drop/edit/:uuid",
          name: "Drop Edit",
          component: dropEdit,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.moveDrop.drop.edit
          }
        },
        {
          path: "/drop/show/:uuid",
          name: "Drop Show",
          component: dropShow,
          meta: {
            requiresAuth: true,
            enabled: permissions.rigs.tabs.moveDrop.drop.show
          }
        },
        {
          path: "/logout",
          name: "logout",
          component: () => null,
          meta: {
            requiresAuth: false,
            enabled: true
          }
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/login",
          name: "page-login",
          component: () => import("@/views/pages/Login.vue"),
          meta: {
            requiresAuth: false,
            enabled: true
          }
        },
        {
          path: "/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
          meta: {
            requiresAuth: false,
            enabled: true
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/error-404"
    }
  ]
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  if(to.name === "logout"){
    store.dispatch("auth/logout").then(_=>{}).catch(_=>{});
    return next();
  }

  if (!store.state.auth.access_token && to.meta.requiresAuth) {
    return next("/login");
  }
  if ((store.state.auth.access_token && !to.meta.requiresAuth)
    || (store.state.auth.access_token && to.meta.requiresAuth && !to.meta.enabled)) {
    return next("/");
  }
  next();
});

export default router;
