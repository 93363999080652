<template>
    <div>
      <ul>
        <li @click="getData(1, $event)"><button :disabled="listData.current_page === 1"><font-awesome-icon icon="angle-double-left"></font-awesome-icon></button></li>
        <li  @click="getData(--listData.current_page, $event)"><button :disabled="listData.current_page === 1"><font-awesome-icon icon="angle-left"></font-awesome-icon></button></li>
        <li :class="{'active': listData.current_page === number}" :key="index" v-for="(number, index) in total_pages" @click="getData(number, $event)"><button :class="{'active': listData.current_page === number}" :disabled="listData.current_page === number">{{number}}</button></li>
        <li  @click="getData(++listData.current_page, $event)"><button :disabled="listData.current_page === total_pages"><font-awesome-icon icon="angle-right"></font-awesome-icon></button></li>
        <li @click="getData(total_pages, $event)"><button :disabled="listData.current_page === total_pages"><font-awesome-icon icon="angle-double-right"></font-awesome-icon></button></li>
      </ul>
    </div>
</template>

<script>
    export default {
        name: "Pagination",
      props: {
          url: {
            type: String,
            required: true
          },
        params: {
          type: String,
          required: false,
          default: ()=> null
        },
        listData: {
            type: Object,
            required: true
        }
      },
      methods: {
          getData(page, event){
            const btn = event.currentTarget.querySelector("button");
            if(btn.disabled) return;
            this.$vs.loading();
            this.$authHTTP.get(this.url + "?page="+ page + (this.params ? "&" + this.params : ""))
            .then(r => {
                this.$emit("data-changed", r.data);
                this.$vs.loading.close();
            })
            .catch(e=> {
              this.$vs.loading.close();
              console.log(e);
            })
          }
      },
      computed:{
          total_pages(){
            if(!this.listData.total || !this.listData.per_page) return 1;
            return Math.ceil(this.listData.total / this.listData.per_page);
          }
      }
    };
</script>

<style scoped lang="scss">
  .active {
    background-color: rgba(147,138,244, 1);
  }
  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;
    ul {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      width: fit-content;

      li {
        display: inline-block;
        padding: 10px;
        margin: 1px;
        color: black;
        border-radius: 50%;
        width: 40px;
        text-align: center;
        cursor: pointer;
        &:hover:not(.active) {
          background-color: rgba(147,138,244, 0.5);
          button {
            font-weight: bold;
            cursor: pointer;
          }
        }
        button {
          border: none;
          background-color: transparent !important;
        }
      }
    }
  }
</style>
