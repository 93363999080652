<template>
    <div>
      <font-awesome-icon icon="home" class="cursor-pointer active-route" @click="$router.push('/')"/>
      <span> {{separator}} </span>
      <span :class="{'active-route cursor-pointer': routeSegments > 1, 'disabled-route' : routeSegments <= 1 }" @click="goToLink"> {{getTitle()}} </span>
      <span v-if="getSubtitle()"> {{separator}} </span>
      <span class="disabled-route" v-if="getSubtitle()"> {{getSubtitle()}} </span>
    </div>
</template>

<script>
    export default {
        name: "Breadcrumb",
      props: {
          separator: {
            type: String,
            required: false,
            default: '/'
          },
        // title: {
        //     type: String,
        //     required: false,
        //     default: this.getTitle()
        // },
        // subtitle:{
        //     type: String,
        //   required: false,
        //   default: this.getSubtitle()
        // }
      },
      methods: {
        getTitle(){
          let title = "";
          var splittedRouteName = this.$route.name.split(" ");
          for(let i = 0; i < splittedRouteName.length - 1; i++){
            title += splittedRouteName[i] + " ";
          }
          return title;
        },
        getSubtitle(){
          return this.$route.name.split(" ").length > 1 ? this.$route.name.split(" ")[this.$route.name.split(" ").length - 1] : null;
        },
        goToLink(){
          this.getSubtitle() ? this.$router.go(-1) : this.$router.push('/' + this.$route.name.split(" ")[0].toLowerCase());
        },
      },
      computed: {
        routeSegments(){
          return this.$route.name.split(" ").length;
        }
      },
      mounted() {
      }
    };
</script>

<style scoped lang="scss">
  .cursor-pointer {
    cursor: pointer;
  }
  .active-route{
    color: #0075b3;
  }
  .disabled-route {
    color: gray;
  }
</style>
