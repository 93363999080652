<template>
    <vs-row>
      <vs-col vs-w="12" style="margin-bottom: 30px;">
        <Breadcrumb></Breadcrumb>
      </vs-col>
      <vs-col vs-w="5" vs-sm="12">
        <vs-input v-model="user.name" label="Name" class="w-100 p-3"></vs-input>
      </vs-col>
      <vs-col vs-w="5" vs-sm="12">
        <vs-input v-model="user.code" label="Code" class="w-100 p-3"></vs-input>
      </vs-col>
      <vs-col vs-w="2" vs-sm="12" style="margin-top: 30px; padding-left: 10px">
        <vs-button @click="generateCode">Generate Code</vs-button>
      </vs-col>
      <vs-col vs-w="12" style="margin-top: 30px">
        <vs-radio v-model="user.active" vs-name="activity" vs-value="1" class="radio">Active</vs-radio>
        <vs-radio v-model="user.active" vs-name="activity" vs-value="0" class="radio">Inactive</vs-radio>
      </vs-col>
      <vs-col vs-w="12" vs-type="flex" vs-justify="flex-end" vs-align="center" style="margin-top: 100px">
        <vs-button @click="$router.go(-1)" style="margin-right: 10px">Back</vs-button>
        <vs-button @click="confirmSave" :disabled="invalidData">Save</vs-button>
      </vs-col>
    </vs-row>
</template>

<script>
  import Breadcrumb from "../../../layouts/components/Breadcrumb";
    export default {
        name: "add",
      components: {
          Breadcrumb
      },
      data(){
          return {
            user: {
              name: "",
              code: "",
              uuid: this.$uuid.v4(),
              active: 1
            }
          }
      },
      methods: {
          generateCode(){
            const random = Math.random().toString(36).substr(2,7);
            const time = new Date().getTime().toString().substr(6);
            this.user.code = random + time;
          },
        confirmSave(){
          this.$vs.dialog({
            title: "Confirm",
            text: "Are you sure that you want to save this item?",
            color: "primary",
            acceptText: "I'm sure",
            accept: () => this.saveData(),
            type: "confirm"
          });
        },
        saveData(){
          this.$vs.loading();
          const payload = {
            name: this.user.name.trim(),
            code: this.user.code.trim(),
            active: this.user.active,
            uuid: this.user.uuid
          }
          this.$authHTTP.post("clients/save", payload)
          .then(r => {
            this.$vs.notify({
              title: 'Success',
              text: r.data,
              color: 'primary'
            });
            this.$router.go(-1);
          })
          .catch(e => {
            console.log(e);
            this.$vs.notify({
              title: 'Error',
              text: e,
              color: 'danger'
            });
          })
          .finally(()=>{
            this.$vs.loading.close()
          });
        }
      },
      computed:{
          invalidData(){
            if(this.user.name.trim() && this.user.code.trim() && this.user.uuid && this.user.active){
              return false
            }
            return true;
          }
      }
    };
</script>

<style scoped lang="scss">
.w-100 {
  width: 100% !important;
}
</style>
