<template>
  <vs-row>
    <vs-col vs-w="12" style="margin-bottom: 20px">
      <Breadcrumb></Breadcrumb>
    </vs-col>
    <vs-col vs-w="4" vs-sm="6" class="px-1">
      <vs-select class="w-100" label="Clients" v-model="selectedClient" placeholder="Choose Client" v-if="clients.length > 0 && $store.state.auth.owner">
        <vs-select-item value="" text="All"></vs-select-item>
        <vs-select-item v-for="(client, index) in clients" :key="index" :value="client.id"
                        :text="client.name"></vs-select-item>
      </vs-select>
    </vs-col>
    <vs-col vs-w="4" vs-sm="6" class="px-1">
      <vs-select class="w-100" label="Rigs" v-model="selectedRig" placeholder="Choose Rig" v-if="rigs.length > 0 && $store.state.auth.owner">
        <vs-select-item value="" text="All"></vs-select-item>
        <vs-select-item v-for="(rig, index) in rigs" :value="rig.id" :key="index" :text="rig.name"></vs-select-item>
      </vs-select>
    </vs-col>
    <vs-col vs-w="4" vs-sm="12" class="px-1" vs-type="flex" vs-justify="flex-end" vs-align="flex-end" style="margin-top: 5px;">
      <vs-button v-if="permissions.add" @click="$router.push('/visit/add/')">
        <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Add
      </vs-button>
    </vs-col>
    <vs-col class="mt-3">
      <calendar-view
        :show-date="showDate"
        display-period-uom="month"
        :display-period-count="1"
        :starting-day-of-week="6"
        :events="list"
        @click-event="editItem"
        class="theme-default holiday-us-traditional holiday-us-official">
        <calendar-view-header
          slot="header"
          slot-scope="t"
          :header-props="t.headerProps"
          @input="setShowDate" />
      </calendar-view>

    </vs-col>
  </vs-row>
</template>

<script>
  import Breadcrumb from "../../../layouts/components/Breadcrumb";
  import CrudActions from "../../../layouts/components/CrudActions";
  import Datepicker from "vuejs-datepicker";
  import { CalendarView, CalendarViewHeader } from "vue-simple-calendar";
  require("vue-simple-calendar/static/css/default.css");
  //require("vue-simple-calendar/static/css/holidays-us.css");
  export default {
    name: "list",
    components:{
      Breadcrumb,
      CrudActions,
      Datepicker,
      CalendarView,
      CalendarViewHeader,
    },
    data(){
      return {
        list: [],
        clients: [],
        selectedClient: "",
        rigs: [],
        selectedRig: "",
        showDate: new Date()
      }
    },
    methods:{
      setShowDate(d) {
        this.showDate = d;
      },
      customFormatter(date) {
        return this.$moment(date).format('DD/M/YYYY');
      },
      getData(){
        this.$vs.loading();
        this.$authHTTP.get(`visits/list?client=${this.selectedClient}&rig=${this.selectedRig}`)
          .then(r => {
            this.$vs.loading.close()
            const data = r.data;
            this.list = [];
            for(const i in data){
              const item = data[i];
              const count = data.filter(x => x.date === item.date).length;
              this.list.push({
                id: item.id,
                startDate: new Date(item.date),
                endDate: new Date(item.date),
                title: count,
                url: item.UUID,
                classes: this.permissions.showDay ? ["item", "cursor-pointer"] : ["item", "cursor-default"]
              });
            }
          })
          .catch(e => {
            this.$vs.loading.close()
            console.log(e);
          });
      },
      editItem(item){
        if(!this.permissions.showDay) return;
        const date = new Date(item.originalEvent.startDate);
        this.$router.push(`visit/${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` );
      },
      deleteItem(uuid){
        this.$vs.loading();
        this.$authHTTP.delete('visits/delete/' + uuid)
          .then(r => {
            const index = this.list.data.findIndex(x =>  x.UUID === uuid );
            this.list.data.splice(index, 1);
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: r.data
            });
          }).catch(e => {
          console.log(e);
          this.$vs.notify({
            title: 'Error',
            text: e.response.data,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          });
        }).finally(() => {
          this.$vs.loading.close();
        });
      },
      confirmDelete(id){
        this.$vs.dialog({
          title: "Confirm",
          text: "Are you sure that you want to delete this item?",
          color: "danger",
          acceptText: "I'm sure",
          accept: () => this.deleteItem(id),
          type: "confirm"
        });
      },
      itemOrder(data){
        const index = this.list.data.findIndex(item => {
          return item.id === data.id;
        });
        return index + 1;
      },
      getClients(){
        this.$vs.loading();
        this.$authHTTP.get("clients/all")
          .then(r => {
            this.$vs.loading.close()
            this.clients = r.data.filter(x=> x.id !== 1);
          })
          .catch(e => {
            this.$vs.loading.close()
            console.log(e);
          });
      },
      getRigs(){
        this.$vs.loading();
        this.$authHTTP.get("rigs/all")
          .then(r => {
            this.$vs.loading.close()
            this.rigs = r.data;
          })
          .catch(e => {
            this.$vs.loading.close()
            console.log(e);
          });
      }
    },
    computed:{
      permissions(){
        return this.$store.state.auth.permissions.visits;
      }
    },
    watch: {
      selectedClient(){
        this.getData();
      },
      selectedRig(){
        this.getData();
      },
    },
    mounted() {
      this.getData();
      this.getClients();
      this.getRigs();
    }
  };
</script>

<style lang="scss">
.item {
  background-color: darkred;
  color: #fff;
  text-align: center;
}
  .cursor-pointer {
    cursor: pointer;
  }
  .cursor-default {
    cursor: default;
  }
.cursor-default:hover {
  text-decoration: none !important;
}
</style>
