export default {
  home: {
    route: true,
  },
  rigs: {
    route: true,
    show: true,
    add: false,
    edit: false,
    delete: false,
    tabs:{
      specifications: {
        show: true,
        edit: false,
      },
      inspection: {
        show: true,
        preliminary: {
          list: true,
          add: false,
          show: true,
          edit: false,
          delete: false,
          download: false
        },
        closing: {
          list: true,
          show: true,
          edit: false
        },
        final: {
          list: true,
          show: true,
          edit: false,
          download: true,
          files :{
            delete: false,
            download: true,
          }
        },
      },
      audit: {
        show: true,
        preliminary: {
          list: true,
          add: false,
          show: true,
          edit: false,
          download: false
        },
        closing: {
          list: true,
          show: true,
          edit: false
        },
        final: {
          list: true,
          show: true,
          edit: false,
          download: true,
          files :{
            delete: false,
            download: true,
          }
        },
      },
      acceptance: {
        show: true,
        list:{
          show: true,
          add: false,
          edit: false,
          download: false
        }
      },
      stopCard: {
        show: false
      },
      qshe: {
        show: false,
        list:{
          show: false
        }
      },
      moveDrop: {
        show: true,
        move: {
          list: true,
          add: false,
          show: true,
          edit: false,
          download: true,
          files:{
            delete: false,
            download: true
          }
        },
        drop:{
          list: true,
          add: false,
          show: true,
          edit: false,
          download: true,
          files:{
            delete: false,
            download: true
          }
        }
      }
    },
  },
  visits: {
    route: true,
    showDay: true,
    show: true,
    add: true,
    edit: true,
    delete: true
  },
  clients: {
    route: false,
    add: false,
    edit: false,
    delete: false
  },
  users: {
    route: false,
    add: false,
    edit: false,
    delete: false
  },
}
