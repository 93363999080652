<template>
  <vs-row>
    <vs-col vs-w="12">
      <Breadcrumb></Breadcrumb>
    </vs-col>
    <vs-col vs-w="6">
      <a v-if="permissions.download" :href="getFileLink" target="_self" style="margin-top: 30px; display: block">Download Original File</a>
    </vs-col>
    <vs-col vs-w="5" vs-offset="1">
      <vs-select label="Sheets" v-model="selectedSheet" placeholder="Choose zone"
                 class="w-100">
        <vs-select-item v-for="(sheet, index) in processedSheets" :key="index" :value="sheet.name"
                        :text="sheet.name"></vs-select-item>
      </vs-select>
    </vs-col>
    <vs-row class="print">
      <vs-col vs-w="12" v-if="selectedSheet && !/summary/i.test(selectedSheet)" style="overflow: auto">
        <table id="summary">
          <tbody>
          <tr>
            <th></th>
            <th>Total</th>
            <th>Open</th>
            <th>In Progress</th>
            <th>Closed</th>
            <th>%Close</th>
          </tr>
          <tr>
            <th class="critical">Critical</th>
            <td>{{criticalCount}}</td>
            <td>{{criticalAndInProgressCount}}</td>
            <td>{{criticalAndOpenCount}}</td>
            <td>{{criticalAndClosedCount}}</td>
            <td>{{criticalClosedPercentage}}</td>
          </tr>
          <tr>
            <th class="major">Major</th>
            <td>{{majorCount}}</td>
            <td>{{majorAndOpenCount}}</td>
            <td>{{majorAndInProgressCount}}</td>
            <td>{{majorAndClosedCount}}</td>
            <td>{{majorClosedPercentage}}</td>
          </tr>
          <tr>
            <th class="minor">Minor</th>
            <td>{{minorCount}}</td>
            <td>{{minorAndOpenCount}}</td>
            <td>{{minorAndInProgressCount}}</td>
            <td>{{minorAndClosedCount}}</td>
            <td>{{minorClosedPercentage}}</td>
          </tr>
          <tr>
            <th class="all">All</th>
            <td>{{allPriorityCount}}</td>
            <td>{{allOpenStatus}}</td>
            <td>{{allInProgressStatus}}</td>
            <td>{{allClosedStatus}}</td>
            <td>{{allClosedPercentage}}</td>
          </tr>
          </tbody>
        </table>
      </vs-col>
      <vs-col vs-w="12" v-if="selectedSheet && !/summary/i.test(selectedSheet)" style="overflow: auto">
        <table id="details">
          <tbody>
          <tr v-for="(row, index) in currentSheet.rows" :key="index" :class="getRowColor(row)">
            <td v-for="(cell, index) in row" :key="index" :class="getCellColor(cell)">
              <span v-if="cell && (!cell.type || cell.type !== 'image')">{{cell.content}}</span>
              <span v-else-if="cell && cell.type && cell.type === 'image' && cell.content">
                <img :key="updateImage" :src="getImagePath(cell.content)" alt="Report Image" width="50" height="50" @click="viewImage(cell.content, row)">
                </span>
            </td>
          </tr>
          </tbody>
        </table>
        <vs-row>
          <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" class="print-hidden">
            <vs-button @click="$router.go(-1)"><font-awesome-icon icon="arrow-left"></font-awesome-icon>&nbsp;Back</vs-button>
            <vs-button v-if="permissions.edit" class="ml-1" @click="$router.push('/audit/edit/' + $route.params.uuid)"><font-awesome-icon icon="edit"></font-awesome-icon>&nbsp;Edit</vs-button>
          </vs-col>
        </vs-row>
        <vs-popup fullscreen :title="displayImageTitle" :active.sync="viewImagePopup" class="popup">
          <div>
            <img :src="getImagePath(displayImageURL)" alt="Report Image">
          </div>
        </vs-popup>
      </vs-col>
      <vs-col vs-w="6" vs-sm="12" class="m-auto"  v-else-if="selectedSheet && /summary/i.test(selectedSheet)"  style="background-color: #fff;">
        <chart :chart-data="getChartData()"></chart>
      </vs-col>
    </vs-row>
  </vs-row>
</template>

<script>
  import XLSX from "xlsx";
  import XLS from "xlsx";
  import Chart from "../../../../../layouts/components/Chart";

  import Breadcrumb from "../../../../../layouts/components/Breadcrumb";

  export default {
    name: "audit-show",
    components: { Breadcrumb,Chart },
    data() {
      return {
        file: null,
        reportUUID: "",
        processedSheets: [],
        selectedSheet: "",
        updateImage: 0,
        viewImagePopup: false,
        displayImageURL: '',
        displayImageTitle: "",
        originalFile: "",
        alphabet: "ABCDEFGHIGKLMNOPQRSTUVWXYZ",
      };
    },
    methods: {
      getChartData(){
        const sheet = this.processedSheets.find(sheet =>{
          return /summary/i.test(sheet.name);
        });
        let criticalOpen = 0;
        let criticalClosed = 0;
        let majorOpen = 0;
        let majorClosed = 0;
        let minorOpen = 0;
        let minorClosed = 0;
        const row15 = sheet.rows.find(row =>{
          if(row){
            if(row[0]){
              return /^[a-z]15$/i.test(row[0].position);
            }
          }
          return false;
        });
        const row16 = sheet.rows.find(row =>{
          if(row){
            if(row[0]){
              return /^[a-z]16$/i.test(row[0].position);
            }
          }
          return false;
        });
        console.log({row15, row16});
        const B15 = row15.find(cell=>{
          if(cell){
            return /^b15$/i.test(cell.position);
          }
        });
        criticalOpen = +B15.content;

        const B16 = row16.find(cell=>{
          if(cell){
            return /^b16$/i.test(cell.position);
          }
        });
        criticalClosed = +B16.content;

        const C15 = row15.find(cell=>{
          if(cell){
            return /^c15$/i.test(cell.position);
          }
        });
        majorOpen = +C15.content;

        const C16 = row16.find(cell=>{
          if(cell){
            return /^c16$/i.test(cell.position);
          }
        });
        majorClosed = +C16.content;

        const D15 = row15.find(cell=>{
          if(cell){
            return /^d15$/i.test(cell.position);
          }
        });
        minorOpen = +D15.content;

        const D16 = row16.find(cell=>{
          if(cell){
            return /^d16$/i.test(cell.position);
          }
        });
        minorClosed = +D16.content;

        return {
          labels: ["Critical", "Major", "Minor"],
          datasets: [
            {
              label: 'Open',
              backgroundColor: 'blue',
              data: [criticalOpen, majorOpen, minorOpen]
            }, {
              label: 'Closed',
              backgroundColor: 'green',
              data: [criticalClosed, majorClosed, minorClosed]
            }
          ]
        }
      },
      getRowColor(row) {
        if (row && row.length > 0 && row[0].content) {
          if (row[0].content.match(/(no)+/i)) {
            return "table-header";
          }
          if (row[0].content.match(/^\d+$/i)) {
            return "table-title";
          }
          if (row[0].content.match(/^\d+\.\d+$/i)) {
            return "table-subtitle";
          }
        }
      },
      getCellColor(cell) {
        if (cell && cell.content && cell.position && /^[eg]\d+/i.test(cell.position)) {
          if (cell.content.match(/critical/i)) {
            return "critical";
          }
          if (cell.content.match(/minor/i)) {
            return "minor";
          }
          if (cell.content.match(/major/i)) {
            return "major";
          }
          if (cell.content.match(/open/i)) {
            return "open";
          }
          if (cell.content.match(/closed/i)) {
            return "closed";
          }
          if (cell.content.match(/progress/i)) {
            return "progress";
          }
        }
      },
      getImagePath(data){
        if(data) {
          return process.env.VUE_APP_BASE_URL.split('api')[0] + data;
        }
        return process.env.VUE_APP_BASE_URL.split('api')[0] + 'uploads/images/logo.png';
      },
      viewImage(url, row) {
        this.displayImageURL = url;
        this.displayImageTitle = row[2].content;
        this.viewImagePopup = true;
      },
      getData(){
        this.$vs.loading();
        this.$authHTTP.get("reports/audit/" + this.$route.params.uuid)
          .then(r=>{
            console.log(r.data);
            this.originalFile = process.env.VUE_APP_BASE_URL + "/" + r.data.sheet;
            this.processedSheets = JSON.parse(r.data.data);
          })
          .catch(e=>{
            console.log(e);
          })
          .finally(()=>{
            this.$vs.loading.close();
          });
      }
    },
    computed: {
      permissions(){
        return this.$store.state.auth.permissions.rigs.tabs.audit.preliminary;
      },
      getFileLink(){
        return process.env.VUE_APP_BASE_URL + '/reports/audit/download/' + this.$route.params.uuid + "?token=" + this.$store.state.auth.access_token;
      },
      currentSheet() {
        const selectedSheet = this.processedSheets.find(x => {
          return x.name === this.selectedSheet;
        });
        return selectedSheet ? selectedSheet : {};
      },
      criticalCount() {
        return this.currentSheet.rowsPriority.filter(priority => {
          return priority === "critical";
        }).length;
      },
      majorCount() {
        return this.currentSheet.rowsPriority.filter(priority => {
          return priority === "major";
        }).length;
      },
      minorCount() {
        return this.currentSheet.rowsPriority.filter(priority => {
          return priority === "minor";
        }).length;
      },
      allPriorityCount() {
        return this.criticalCount + this.majorCount + this.minorCount;
      },
      criticalAndClosedCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "critical" && status === "closed") {
            count++;
          }
        }
        return count;
      },
      criticalAndInProgressCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "critical" && status === "progress") {
            count++;
          }
        }
        return count;
      },
      criticalAndOpenCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "critical" && status === "open") {
            count++;
          }
        }
        return count;
      },
      majorAndClosedCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "major" && status === "closed") {
            count++;
          }
        }
        return count;
      },
      majorAndInProgressCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "major" && status === "progress") {
            count++;
          }
        }
        return count;
      },
      majorAndOpenCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "major" && status === "open") {
            count++;
          }
        }
        return count;
      },
      minorAndClosedCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "minor" && status === "closed") {
            count++;
          }
        }
        return count;
      },
      minorAndInProgressCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "minor" && status === "progress") {
            count++;
          }
        }
        return count;
      },
      minorAndOpenCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "minor" && status === "open") {
            count++;
          }
        }
        return count;
      },
      allOpenStatus() {
        return this.criticalAndOpenCount + this.majorAndOpenCount + this.minorAndOpenCount;
      },
      allInProgressStatus() {
        return this.criticalAndInProgressCount + this.majorAndInProgressCount + this.minorAndInProgressCount;
      },
      allClosedStatus() {
        return this.criticalAndClosedCount + this.majorAndClosedCount + this.minorAndClosedCount;
      },
      criticalClosedPercentage() {
        const percentage = Math.floor((this.criticalAndClosedCount / this.criticalCount) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      majorClosedPercentage() {
        const percentage = Math.floor((this.majorAndClosedCount / this.majorCount) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      minorClosedPercentage() {
        const percentage = Math.floor((this.minorAndClosedCount / this.minorCount) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      allClosedPercentage() {
        const percentage = Math.floor((this.allClosedStatus / this.allPriorityCount) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      overAllCriticalClosedPercentage() {
        let criticalCount = 0;
        let closedCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "critical") {
              criticalCount++;
            }
            if (priority === "critical" && status === "closed") {
              closedCount++;
            }
          }
        }
        const percentage = Math.floor((closedCount / criticalCount) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      overAllMajorClosedPercentage() {
        let majorCount = 0;
        let closedCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "major") {
              majorCount++;
            }
            if (priority === "major" && status === "closed") {
              closedCount++;
            }
          }
        }
        const percentage = Math.floor((closedCount / majorCount) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      overAllMinorClosedPercentage() {
        let minorCount = 0;
        let closedCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "minor") {
              minorCount++;
            }
            if (priority === "minor" && status === "closed") {
              closedCount++;
            }
          }
        }
        const percentage = Math.floor((closedCount / minorCount) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      overAllPrioritiesClosedPercentage() {
        let count = 0;
        let closedCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "minor" || priority === "major" || priority === "critical") {
              count++;
            }
            if ((priority === "minor" || priority === "major" || priority === "critical") && status === "closed") {
              closedCount++;
            }
          }
        }
        const percentage = Math.floor((closedCount / count) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
    },
    mounted() {
      this.getData();
    }
  };
</script>

<style lang="scss" scoped>
  table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    color: #000;
    img {
      cursor: pointer;
      margin-top: 10px;
    }
  }

  table, th, td {
    border: 1px solid rgba(0, 0, 0, 0.5);
    text-align: center;
  }

  td, th {
    width: available;
    padding: 5px;
  }

  .w-100 {
    width: 100% !important;
  }

  .table-header {
    background-color: blue;
    color: #fff;
    font-size: 1.1rem;
    font-weight: bold;
  }

  .table-title {
    background-color: #000;
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
  }

  .table-subtitle {
    background-color: #ccc;
    font-weight: bold;
    font-size: 1rem;
  }

  .critical {
    color: red;
    font-weight: bold;
  }

  .minor {
    color: yellow;
    font-weight: bold;
  }

  .major {
    color: blue;
    font-weight: bold;
  }

  .open {
    background-color: red;
    font-weight: bold;
  }

  .closed {
    background-color: green;
    font-weight: bold;
  }

  .progress {
    background-color: orangered;
    font-weight: bold;
  }

  #summary, #details {
    margin: 10px 0;
  }
  .popup {
    div {
      width: 100%;
      text-align: center;
      img {
        max-width: 100%;
        height: auto;
      }
    }

  }

  .stripe {
    background-color: #ccc;
  }
</style>
