<template>
    <vs-row>
      <vs-col vs-w="12">
        <Breadcrumb></Breadcrumb>
      </vs-col>
      <vs-col vs-w="12">
        <vs-upload
          :action="getUploadFileEndPoint"
          automatic
          text="Upload Files"
          fileName="file"
          :data="{rigUUID: report.rigUUID,
            reportUUID: report.UUID
          }"
          multiple
          single-upload
          @on-success="successUpload"
          @on-error="failedUpload"
          @on-delete="deletedUpload"
        />
      </vs-col>
      <vs-col vs-w="12" v-if="report.files.data.length > 0">
        <vs-table search stripe :max-items="report.files.per_page" :data="report.files.data">
          <template slot="thead">
            <vs-th>
              No.
            </vs-th>
            <vs-th>
              File Name
            </vs-th>
            <vs-th>
              Created at
            </vs-th>
            <vs-th>
              Actions
            </vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">

              <vs-td :data="itemOrder(tr)">
                {{ ((report.files.current_page - 1) * report.files.per_page) + itemOrder(tr) }}
              </vs-td>
              <vs-td :data="tr.file.split('/').pop()">
                {{ tr.file.split("/").pop() }}
              </vs-td>
              <vs-td :data="tr['created_at']">
                {{tr["created_at"] | moment("calendar")}}
              </vs-td>
              <vs-td :data="tr">
                <crud-actions
                  :id="tr.id+''"
                  :force-download="true"
                  @downloadClicked="downloadFile"
                  @trashClicked="confirmDelete"
                  :permissions="permissions.files"
                  :trash="false"></crud-actions>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-col>
      <vs-col vs-w="12" vs-type="flex" vs-justify="flex-end" vs-align="center" style="margin-top: 10px;">
        <vs-button style="margin-right: 10px;" @click="$router.go(-1)"><font-awesome-icon icon="times"></font-awesome-icon>&nbsp;Cancel</vs-button>
        <vs-button @click="confirmSave"><font-awesome-icon icon="save"></font-awesome-icon>&nbsp;Save</vs-button>
      </vs-col>
    </vs-row>
</template>

<script>
    import store2 from "store2";
    import Breadcrumb from "../../../../../../layouts/components/Breadcrumb";
    import CrudActions from "../../../../../../layouts/components/CrudActions";

    export default {
        name: "edit",
      components:{
        Breadcrumb,
        CrudActions
      },
      data(){
          return {
            report: {
              id: 0,
              UUID: "",
              rigUUID: "",
              files: {
                data: []
              }
            },
            files: new Set(),
          }
      },
      methods: {
          confirmSave(){
            this.$vs.dialog({
              color: "primary",
              type: "confirm",
              title: "Save Report",
              text: "Are you sure you want to save this report?",
              accept: this.saveReports
            });
          },
        saveReports(){
          this.$vs.loading();
          const payload = {
            move_report_id: this.report.id,
            rigUUID: this.report.rigUUID,
            UUID: this.report.UUID,
            files: [...this.files]
          }
          this.$authHTTP.post("reports/move_reports/save-files", payload)
            .then(r=>{
              this.$vs.notify({ color: "success", title: "Upload Success", text: "Files are uploaded successfully." });
              this.$router.push("/move/show/" + this.report.UUID);
            })
            .catch(e=>{
              console.log(e);
              this.$vs.notify({ color: "error", title: "Upload Fail", text: "Failed to upload files" });
            })
            .finally(()=>{
              this.$vs.loading.close();
            })
        },
        confirmDelete(id){
          this.$vs.dialog({
            color: "danger",
            type: "confirm",
            title: "Delete File",
            text: "Are you sure you want to delete this file?",
            accept: this.deleteFile.bind(null, id)
          });
        },
        deleteFile(id){
            this.$vs.loading();
            this.$authHTTP.delete("reports/move_reports/delete-file/"+id)
          .then(r=>{
            const index = this.report.files.data.findIndex(file => {
              return file.id === +id;
            });
            this.report.files.data.splice(index, 1);
            this.$vs.notify({color:'success',title:'Delete Success',text:'File is deleted successfully.'});
          })
          .catch(e=>{
            console.log(e);
            this.$vs.notify({color:'error',title:'Delete Fail',text:'Failed to delete file'});
          })
          .finally(()=>{
            this.$vs.loading.close();
          })
        },
        downloadFile(id){
            location.href = process.env.VUE_APP_BASE_URL + "/reports/move_reports/download-file/" + id + "?token=" + store2.get("access_token");
        },
        successUpload(data){
          this.files.add(data.currentTarget.responseText);
          //this.$vs.notify({color:'success',title:'Upload Success',text:'Image is uploaded successfully.'});
        },
        failedUpload(){
          this.$vs.notify({color:'danger',title:'Upload Failed',text:'Unable to upload image.'});
        },
        deletedUpload(file){
          this.files.delete(`uploads/rigs/${this.report.rigUUID}/reports/moveReport/${this.report.UUID}/${file.name}`);
          this.$vs.notify({color:'success',title:'Delete Success',text:'Image is deleted successfully.'});
        },
        itemOrder(data) {
          const index = this.report.files.data.findIndex(item => {
            return item.id === data.id;
          });
          return index + 1;
        },
      },
      computed: {
        getUploadFileEndPoint(){
          return process.env.VUE_APP_BASE_URL + "/reports/move_reports/upload/file?token=" + store2.get("access_token");
        },
        permissions(){
          return this.$store.state.auth.permissions.rigs.moveDrop.move;
        }
      },
      mounted() {
        this.report.UUID = this.$uuid.v4();
        this.report.rigUUID = this.$route.params.uuid;
      }
    };
</script>

<style scoped>

</style>
