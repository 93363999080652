import { noAuthHTTP, authHTTP } from "../../axios";

class AuthAPI {
    #loginURL = "/auth/login";
    #logoutURL = "/auth/logout";

    async login(payload) {
        try {
            const data = await noAuthHTTP.post(this.#loginURL, payload);
            if (data.status) {
                return data.data;
            }
            return data.error;
        } catch (e) {
            throw e.response.data.error;
        }
    }

  async logout(){
    try {
      await authHTTP.post(this.#logoutURL);
    }catch (e) {
      throw e.response.data.error;
    }
  }
}

export default AuthAPI;
