export default {
  access_token: "",
  token_type: "",
  expires_in: 0,
  user_name: "",
  user_permission: "",
  client_code: "",
  owner: "",
  temp_data: null,
  boolKeywords: ["according", "video camera", "spring", "locking", "h2s", "accumulator"],
  hiddenKeyword: ["id", "title", "rig_id", "rig_spec_id", "sub_spec_id", "subSpecs"],
  permission: "",
  permissions: {},
  permissionsEnum: {
    admin: 1,
    editor: 2,
    viewer: 3,
    client: 4
  }
};
