<template>
    <div class="flex-centered">
      <div class="cursor-pointer download" v-if="permissions ? permissions.download && (files.length > 0 || forceDownload) : view" @click="$emit('downloadClicked', id)">
        <font-awesome-icon icon="download" />
      </div>
      <div class="cursor-pointer view" v-if="permissions ? permissions.show : view" @click="$emit('viewClicked', id)">
        <font-awesome-icon icon="eye" />
      </div>
      <div class="cursor-pointer edit" v-if="permissions ? permissions.edit : edit"  @click="$emit('editClicked', id)">
        <font-awesome-icon icon="edit" />
      </div>
      <div class="cursor-pointer trash" v-if="permissions ? permissions.delete : trash"  @click="$emit('trashClicked', id)">
        <font-awesome-icon icon="trash" />
      </div>
    </div>
</template>

<script>
    export default {
        name: "CrudActions",
      props:{
          view: {
            type: Boolean,
            required: false,
            default: true
          },
        edit: {
          type: Boolean,
          required: false,
          default: true
        },
        trash: {
          type: Boolean,
          required: false,
          default: true
        },
        download: {
          type: Boolean,
          required: false,
          default: false
        },
        id: {
          type: String,
          required: true
        },
        permissions:{
            type: Object,
            required: false,
          default: () => null
        },
        files:{
          type: Array,
          required: false,
          default: () => []
        },
        forceDownload: {
            type:Boolean,
          required: false,
          default: false
        }
      }
    };
</script>

<style lang="scss" scoped>
  .cursor-pointer {
    cursor: pointer;
  }
  .view, .edit, .trash, .download{
    display: inline-block;
  }
  .view, .edit, .download {
    margin-right: 5px;
  }

  .flex-centered {
    display: flex;
  }
</style>
