<template>
  <vs-row>
    <vs-col style="margin-bottom: 20px;">
      <Breadcrumb></Breadcrumb>
    </vs-col>
    <vs-col vs-w="4"  vs-sm="12">
      <vs-select label="Clients" v-model="selectedClient" placeholder="Choose Client" v-if="clients.length > 0 && $store.state.auth.owner">
        <vs-select-item value="" text="All"></vs-select-item>
        <vs-select-item v-for="(client, index) in clients" :key="index" :value="client.id"
                        :text="client.name"></vs-select-item>
      </vs-select>
    </vs-col>
    <vs-col vs-w="4" vs-sm="12">
      <vs-select label="Permission" v-model="selectedPermission" placeholder="Choose Activity" v-if="allPermissions.length > 0 && $store.state.auth.owner">
        <vs-select-item value="" text="All"></vs-select-item>
        <vs-select-item v-for="(permission, index) in allPermissions" :value="permission.id" :key="index" :text="permission.type.toUpperCase()"></vs-select-item>
      </vs-select>
    </vs-col>
    <vs-col vs-w="4"  vs-sm="12" vs-type="flex" vs-justify="flex-end" vs-align="flex-end">
      <vs-button v-if="permissions.add" @click="$router.push('/user/add/')">
        <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Add
      </vs-button>
    </vs-col>
    <vs-col vs-w="12">
      <vs-table search stripe :max-items="list.per_page" :data="list.data">
        <template slot="header">
          <h3>
            Users
          </h3>
        </template>
        <template slot="thead">
          <vs-th>
            No.
          </vs-th>
          <vs-th>
            Name
          </vs-th>
          <vs-th>
            Email
          </vs-th>
          <vs-th v-if="$store.state.auth.owner">
            Client
          </vs-th>
          <vs-th>
            Permission
          </vs-th>
          <vs-th>
            Actions
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="itemOrder(tr)">
              {{ ((list.current_page - 1) * list.per_page) + itemOrder(tr) }}
            </vs-td>
            <vs-td :data="tr.name">
              {{tr.name}}
            </vs-td>
            <vs-td :data="tr.email">
              {{tr.email}}
            </vs-td>
            <vs-td :data="tr.client.name" v-if="$store.state.auth.owner">
              {{tr.client.name}}
            </vs-td>
            <vs-td :data="tr.permission.type">
              {{tr.permission.type.toUpperCase()}}
            </vs-td>
            <vs-td :data="tr">
              <crud-actions
                :id="tr.UUID"
                :permissions="permissions"
                @editClicked="editItem"
                @trashClicked="confirmDelete"
              ></crud-actions>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <Pagination url="users/list" :params="`client=${this.selectedClient}&permission=${this.selectedPermission}`" :list-data="list" @data-changed="list = $event"></Pagination>
    </vs-col>
  </vs-row>
</template>

<script>
  import Breadcrumb from "../../../layouts/components/Breadcrumb";
  import Pagination from "../../../layouts/components/Pagination";
  import CrudActions from "../../../layouts/components/CrudActions";
  export default {
    name: "list",
    components:{
      Breadcrumb,
      Pagination,
      CrudActions
    },
    data(){
      return {
        list: {},
        clients: [],
        selectedClient: "",
        allPermissions: [],
        selectedPermission: ""
      }
    },
    methods:{
      getData(){
        this.$vs.loading();
        this.$authHTTP.get(`users/list?client=${this.selectedClient}&permission=${this.selectedPermission}`)
          .then(r => {
            this.$vs.loading.close()
            this.list = r.data;
          })
          .catch(e => {
            this.$vs.loading.close()
            console.log(e);
          });
      },
      editItem(uuid){
        this.$router.push('user/edit/' + uuid);
      },
      deleteItem(uuid){
        this.$vs.loading();
        this.$authHTTP.delete('users/delete/' + uuid)
          .then(r => {
            const index = this.list.data.findIndex(x =>  x.UUID === uuid );
            this.list.data.splice(index, 1);
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: r.data
            });
          }).catch(e => {
          console.log(e);
          this.$vs.notify({
            title: 'Error',
            text: e.response.data,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          });
        }).finally(() => {
          this.$vs.loading.close();
        });
      },
      confirmDelete(id){
        this.$vs.dialog({
          title: "Confirm",
          text: "Are you sure that you want to delete this item?",
          color: "danger",
          acceptText: "I'm sure",
          accept: () => this.deleteItem(id),
          type: "confirm"
        });
      },
      itemOrder(data){
        const index = this.list.data.findIndex(item => {
          return item.id === data.id;
        });
        return index + 1;
      },
      getClients(){
        this.$vs.loading();
        this.$authHTTP.get("clients/all")
          .then(r => {
            this.$vs.loading.close()
            this.clients = r.data;
          })
          .catch(e => {
            this.$vs.loading.close()
            console.log(e);
          });
      },
      getPermissions(){
        this.$vs.loading();
        this.$authHTTP.get("permissions/list")
          .then(r => {
            this.$vs.loading.close()
            this.allPermissions = r.data;
          })
          .catch(e => {
            this.$vs.loading.close()
            console.log(e);
          });
      }
    },
    computed:{
      permissions(){
        return this.$store.state.auth.permissions.users;
      }
    },
    watch: {
      selectedClient(){
        this.getData();
      },
      selectedPermission(){
        this.getData();
      }
    },
    mounted() {
      this.getData();
      this.getClients();
      this.getPermissions();
    }
  };
</script>

<style scoped>

</style>
