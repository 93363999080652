<template>
  <vs-row>
    <vs-col vs-w="12" style="margin-bottom: 30px;">
      <Breadcrumb></Breadcrumb>
    </vs-col>
    <vs-col vs-w="6" v-if="$store.state.auth.owner">
      <h6>Client:</h6>&nbsp;<p>{{visit.client.name}}</p>
    </vs-col>
    <vs-col vs-w="6" class="mt-3">
      <h6>Rig:</h6>&nbsp;<p>{{visit.rig ? visit.rig.name : visit.other_rig}}</p>
    </vs-col>
    <vs-col vs-w="6" class="mt-3">
      <h6>Visit Type:</h6>&nbsp;<p>{{visit.type.name}}</p>
    </vs-col>
    <vs-col vs-w="6" class="mt-3">
      <h6>Date:</h6>&nbsp;<p>{{visit.date}}</p>
    </vs-col>
    <vs-col vs-w="12" class="mt-3">
      <h6>Description:</h6>&nbsp;<p>{{visit.description}}</p>
    </vs-col>
    <vs-col vs-w="12" vs-type="flex" vs-justify="flex-end" class="pr-3">
      <vs-button @click="$router.go(-1)" class="mr-1"><font-awesome-icon icon="arrow-left"></font-awesome-icon>&nbsp;Back</vs-button>
      <vs-button @click="$router.push('/visit/edit/' + $route.params.uuid)"><font-awesome-icon icon="edit"></font-awesome-icon>&nbsp;Edit</vs-button>
    </vs-col>
  </vs-row>
</template>

<script>
  import Breadcrumb from "../../../layouts/components/Breadcrumb";
  export default {
    name: "show",
    components: {
      Breadcrumb
    },
    data(){
      return {
        visit: {
          client:{},
          rig:{},
          type:{}
        }
      }
    },
    methods: {
      getData(){
        this.$vs.loading();
        this.$authHTTP.get(`visits/show/${this.$route.params.uuid}`)
          .then(r => {
            this.visit = r.data;
            this.visit.date = r.data.date.split("-").reverse().join("-");
          })
          .catch(e => {
            console.log(e);
          })
          .finally(()=>{
            this.$vs.loading.close();
          })
      },
    },
   mounted() {
      this.getData();
    }
  };
</script>

<style lang="scss">
  .w-100 {
    width: 100% !important;
  }
  .vdp-datepicker .cell.day.highlighted{
    background: rgba(var(--vs-danger), 0.7);
  }
</style>
