<template>
  <div>
    <vs-col vs-w="12" vs-type="flex" vs-justify="flex-end" vs-align="center">
      <vs-button v-if="permissions.add" @click="$router.push('/acceptance/add/' + $route.params.uuid)">
        <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Add
      </vs-button>
    </vs-col>
    <vs-col vs-w="12">
      <vs-table search stripe :max-items="list.per_page" :data="list.data">
        <template slot="thead">
          <vs-th>
            No.
          </vs-th>
          <vs-th>
            Critical Closed Percentage
          </vs-th>
          <vs-th>
            Major Closed Percentage
          </vs-th>
          <vs-th>
            Minor Closed Percentage
          </vs-th>
          <vs-th>
            Overall Closed Percentage
          </vs-th>
          <vs-th>
            Created at
          </vs-th>
          <vs-th>
            Actions
          </vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">

            <vs-td :data="itemOrder(tr)">
              {{ ((list.current_page - 1) * list.per_page) + itemOrder(tr) }}
            </vs-td>
            <vs-td :data="tr['Critical Closed Percentage']">
              {{tr["Critical Closed Percentage"] + (tr["Critical Closed Percentage"] === "---"? "" : "%") }}
            </vs-td>

            <vs-td :data="tr['Major Closed Percentage']">
              {{tr["Major Closed Percentage"] + (tr["Major Closed Percentage"] === "---" ? "" : "%")}}
            </vs-td>
            <vs-td :data="tr['Minor Closed Percentage']">
              {{tr["Minor Closed Percentage"] + (tr["Minor Closed Percentage"] === "---" ? "" : "%")}}
            </vs-td>
            <vs-td :data="tr['Overall Closed Percentage']">
              {{tr["Overall Closed Percentage"] + (tr["Overall Closed Percentage"] === "---" ? "" : "%")}}
            </vs-td>
            <vs-td :data="tr['created_at']">
              {{tr["created_at"] | moment("calendar")}}
            </vs-td>
            <vs-td :data="tr">
              <crud-actions
                :id="tr.UUID"
                :permissions="permissions"
                @viewClicked="viewItem"
                @editClicked="editItem"
                :trash="false"
                ></crud-actions>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <Pagination :url="`/reports/acceptance/summaries/${this.$route.params.uuid}`" :list-data="list" @data-changed="list = $event"></Pagination>
    </vs-col>
  </div>
</template>

<script>
  import CrudActions from "../../../../../layouts/components/CrudActions";
  import Pagination from "../../../../../layouts/components/Pagination";
    export default {
        name: "list",
      components:{
        CrudActions,
        Pagination
      },
      props:{
        list: {
          type: Object,
          required: true,
          default: () => {
            return {};
          }
        }
      },
      data(){
        return {

        }
      },
      methods:{
        itemOrder(data) {
          const index = this.list.data.findIndex(item => {
            return item.id === data.id;
          });
          return index + 1;
        },
        viewItem(uuid) {
          this.$router.push("/acceptance/show/" + uuid);
        },
        editItem(uuid){
          this.$router.push("/acceptance/edit/" + uuid);
        },
      },
      computed: {
        permissions(){
          return this.$store.state.auth.permissions.rigs.tabs.acceptance.list;
        }
      },
    };
</script>

<style scoped>

</style>
