import store2 from "store2";

export default {
    SET_USER(state, { access_token, token_type, expires_in, user_name, user_permission, client_code, owner }) {
      state.access_token = access_token;
      state.token_type = token_type;
      state.expires_in = expires_in;
      state.user_name = user_name;
      state.user_permission = user_permission;
      state.client_code = client_code;
      state.owner = owner

      for(const [key, value] of Object.entries(state.permissionsEnum)){
        if(value == state.user_permission){
          state.permission = key;
          break;
        }
      }

      store2.set("access_token", access_token);
      store2.set("token_type", token_type);
      store2.set("expires_in", expires_in);
      store2.set("user_name", user_name);
      store2.set("user_permission", user_permission);
      store2.set("client_code", client_code);
      store2.set("owner", owner);
    },
  SET_PERMISSIONS(state, payload){
      state.permissions = payload;
  },
    LOGOUT(state){
      state.access_token = "";
      state.token_type = "";
      state.expires_in = 0;
      state.user_name = "";
      state.user_permission = "";
      state.client_code = "";
      state.owner = "";

      store2.clearAll();
    },

  UPDATE_TIME(state, time){
      state.expires_in = time;
      store2.set("expires_in", time);
  },

  SET_TEMP_DATA(state, payload){
      state.temp_data = payload;
  },
  SET_GENERAL_DATA(state, payload){
    state.temp_data.specs[0].subSpecs[0]["General Data"] = payload;
  }
};
