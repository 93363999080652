<template>
  <vs-row>
    <vs-col vs-w="5">
      <vs-input label="File" :accept="['.xls', '.xlsx']" type="file" @change="readFile" class="w-100"></vs-input>
    </vs-col>
    <vs-col vs-w="5" vs-offset="2" class="mt-1">
      <vs-select label="Sheets" v-model="selectedSheet" placeholder="Choose zone" v-if="processedSheets.length > 0"
                 class="w-100">
        <vs-select-item v-for="(sheet, index) in processedSheets" :key="index" :value="sheet.name"
                        :text="sheet.name"></vs-select-item>
      </vs-select>
    </vs-col>
    <vs-row>
      <vs-col vs-w="12" v-if="selectedSheet && !/summary/i.test(selectedSheet)" style="overflow: auto">
        <table id="summary">
          <tbody>
          <tr>
            <th></th>
            <th>Total</th>
            <th>Open</th>
            <th>In Progress</th>
            <th>Closed</th>
            <th>%Close</th>
          </tr>
          <tr>
            <th class="critical">Critical</th>
            <td>{{criticalCount}}</td>
            <td>{{criticalAndInProgressCount}}</td>
            <td>{{criticalAndOpenCount}}</td>
            <td>{{criticalAndClosedCount}}</td>
            <td>{{criticalClosedPercentage}}</td>
          </tr>
          <tr>
            <th class="major">Major</th>
            <td>{{majorCount}}</td>
            <td>{{majorAndOpenCount}}</td>
            <td>{{majorAndInProgressCount}}</td>
            <td>{{majorAndClosedCount}}</td>
            <td>{{majorClosedPercentage}}</td>
          </tr>
          <tr>
            <th class="minor">Minor</th>
            <td>{{minorCount}}</td>
            <td>{{minorAndOpenCount}}</td>
            <td>{{minorAndInProgressCount}}</td>
            <td>{{minorAndClosedCount}}</td>
            <td>{{minorClosedPercentage}}</td>
          </tr>
          <tr>
            <th class="all">All</th>
            <td>{{allPriorityCount}}</td>
            <td>{{allOpenStatus}}</td>
            <td>{{allInProgressStatus}}</td>
            <td>{{allClosedStatus}}</td>
            <td>{{allClosedPercentage}}</td>
          </tr>
          </tbody>
        </table>
        <table id="details">
          <tbody>
          <tr v-for="(row, index) in currentSheet.rows" :key="index" :class="getRowColor(row)">
            <td v-for="(cell, index) in row" :key="index" :class="getCellColor(cell)" :style="{'min-width' : (cell.type === 'image' ? '120px' : '90px' )}">
              <span v-if="cell && (!cell.type || cell.type !== 'image')">{{cell.content}}</span>
              <span v-else-if="cell && cell.type && cell.type === 'image' && !cell.content">
                <vs-input label="Image" type="file"
                          accept="image/*"
                          class="w-100"
                          @change="uploadImage(currentSheet.name,row, $event)"></vs-input>
              </span>
              <span v-else-if="cell && cell.type && cell.type === 'image' && cell.content">
                <img :key="updateImage" :src="getImagePath(cell.content)" alt="Report Image" width="50" height="50"
                     @click="viewImage(cell.content, row)">
                <vs-input label="Change Image" type="file"
                          accept="image/*"
                          class="w-100"
                          @change="uploadImage(currentSheet.name, row, $event)"></vs-input>
                </span>
            </td>
          </tr>
          </tbody>
        </table>
        <vs-row>
          <vs-col vs-w="12" vs-type="flex" vs-justify="flex-end" vs-align="center">
            <vs-button @click="confirmSave">
              <font-awesome-icon icon="save"></font-awesome-icon>&nbsp;Save
            </vs-button>
          </vs-col>
        </vs-row>
        <vs-popup fullscreen :title="displayImageTitle" :active.sync="viewImagePopup" class="popup">
          <div>
            <img :src="getImagePath(displayImageURL)" alt="Report Image">
          </div>
        </vs-popup>
      </vs-col>
      <vs-col vs-w="6" vs-offset="3"  v-else-if="selectedSheet && /summary/i.test(selectedSheet)">
        <chart :chart-data="getChartData()"></chart>
      </vs-col>
    </vs-row>
  </vs-row>
</template>
<script>
  import XLSX from "xlsx";
  import XLS from "xlsx";
  import Chart from "../../../../../layouts/components/Chart";
  import JSZip from "jszip/dist/jszip.min";
  import Worker from "worker-loader!../../../../../assets/js/ReadFileWorker.js";

  export default {
    name: "audit-add",
    components:{
      Chart
    },
    data() {
      return {
        file: null,
        reportUUID: "",
        processedSheets: [],
        selectedSheet: "",
        updateImage: 0,
        sheet: null,
        viewImagePopup: false,
        displayImageURL: "",
        displayImageTitle: "",
        alphabet: "ABCDEFGHIGKLMNOPQRSTUVWXYZ"

      };
    },
    methods: {
      getChartData(){
        const sheet = this.processedSheets.find(sheet =>{
          return /summary/i.test(sheet.name);
        });
        let criticalOpen = 0;
        let criticalClosed = 0;
        let majorOpen = 0;
        let majorClosed = 0;
        let minorOpen = 0;
        let minorClosed = 0;
        const row15 = sheet.rows.find(row =>{
          if(row){
            if(row[0]){
              return /^[a-z]15$/i.test(row[0].position);
            }
          }
          return false;
        });
        const row16 = sheet.rows.find(row =>{
          if(row){
            if(row[0]){
              return /^[a-z]16$/i.test(row[0].position);
            }
          }
          return false;
        });
        console.log({row15, row16});
        const B15 = row15.find(cell=>{
          if(cell){
            return /^b15$/i.test(cell.position);
          }
        });
        criticalOpen = +B15.content;

        const B16 = row16.find(cell=>{
          if(cell){
            return /^b16$/i.test(cell.position);
          }
        });
        criticalClosed = +B16.content;

        const C15 = row15.find(cell=>{
          if(cell){
            return /^c15$/i.test(cell.position);
          }
        });
        majorOpen = +C15.content;

        const C16 = row16.find(cell=>{
          if(cell){
            return /^c16$/i.test(cell.position);
          }
        });
        majorClosed = +C16.content;

        const D15 = row15.find(cell=>{
          if(cell){
            return /^d15$/i.test(cell.position);
          }
        });
        minorOpen = +D15.content;

        const D16 = row16.find(cell=>{
          if(cell){
            return /^d16$/i.test(cell.position);
          }
        });
        minorClosed = +D16.content;

        return {
          labels: ["Critical", "Major", "Minor"],
          datasets: [
            {
              label: 'Open',
              backgroundColor: 'blue',
              data: [criticalOpen, majorOpen, minorOpen]
            }, {
              label: 'Closed',
              backgroundColor: 'green',
              data: [criticalClosed, majorClosed, minorClosed]
            }
          ]
        }
      },
      readFile(e) {
        this.$vs.loading();
        const self = this;
        this.processedSheets = [];
        const reader = new FileReader();
        const file = e.target.files[0];
        this.sheet = file;
        const isXLSX = file.name.toLowerCase().indexOf(".xlsx") > -1;
        this.$vs.notify({ color: "primary", title: "Action", text: "Reading file ..." });
        if (isXLSX) {
          reader.readAsArrayBuffer(file);
        } else {
          reader.readAsBinaryString(file);
        }
        reader.onload = function(e) {
          const data = e.target.result;
          if(window.Worker){
            self.$vs.notify({ color: "primary", title: "Action", text: "Getting data ..." });
            let worker = new Worker(require("@/assets/js/ReadFileWorker.js"));
            worker.postMessage({file: data, isXLSX: isXLSX});
            worker.onmessage = function (e){
              self.processedSheets = e.data;
              worker.terminate();
              self.reportUUID = self.$uuid.v4();
              self.$vs.loading.close();
              self.$vs.notify({ color: "success", title: "Success", text: "File is read successfully." });
            }
          }else{
            if (isXLSX) {
              self.file = XLSX.read(data, { type: "array", blankrows: true, row: true, sheetStubs: true });
            } else {
              self.file = XLS.read(data, { type: "binary", blankrows: true, row: true, sheetStubs: true });
            }
            self.file.SheetNames.forEach(name => self.processSheet(name));
            self.reportUUID = self.$uuid.v4();
            self.$vs.loading.close();
          }
        };
      },
      getFormattedCells(sheet) {
        const cells = [];
        for (const cell in sheet) {
          if (sheet.hasOwnProperty(cell) && /^[a-n]\d+/i.test(cell)) {
            cells.push({
              position: cell,
              content: sheet[cell].w
            });
          }
        }
        return cells;
      },
      getProcessedSheet(sheetName, cells) {
        const pSheet = {
          name: sheetName,
          rows: []
        };
        for (const currentCell of cells) {
          const index = +currentCell.position.match(/\d+/g)[0];
          if (!pSheet.rows[index]) {
            pSheet.rows[index] = [];
          }
          pSheet.rows[index].push(currentCell);
        }
        return pSheet;
      },
      attachRowPriority(processedSheet) {
        const rows = processedSheet.rows;
        processedSheet.rowsPriority = [];
        parent:
          for (const row of rows) {
            if (row) {
              for (const cell of row) {
                if (cell && cell.content && cell.position) {
                  if (/^e\d+/i.test(cell.position)) {
                    if (cell.content.match(/critical/i)) {
                      processedSheet.rowsPriority.push("critical");
                      continue parent;
                    } else if (cell.content.match(/minor/i)) {
                      processedSheet.rowsPriority.push("minor");
                      continue parent;
                    } else if (cell.content.match(/major/i)) {
                      processedSheet.rowsPriority.push("major");
                      continue parent;
                    }
                  }
                }
              }
              processedSheet.rowsPriority.push("none");
            } else {
              processedSheet.rowsPriority.push("none");
            }
          }
      },
      attachRowStatus(processedSheet) {
        const rows = processedSheet.rows;
        processedSheet.rowsStatus = [];
        for (const row of rows) {
          if (row) {
            const closed = row.find(cell => {
              if (cell && cell.content && cell.position) {
                return cell.content.match(/closed/i) && /^g\d+/i.test(cell.position);
              }
              return false;
            });
            if (closed) {
              processedSheet.rowsStatus.push("closed");
              continue;
            }

            const progress = row.find(cell => {
              if (cell && cell.content) {
                return cell.content.match(/progress/i) && /^g\d+/i.test(cell.position);
              }
              return false;
            });
            if (progress) {
              processedSheet.rowsStatus.push("progress");
              continue;
            }

            const open = row.find(cell => {
              if (cell && cell.content) {
                return cell.content.match(/open/i) && /^g\d+/i.test(cell.position);
              }
              return false;
            });

            if (open) {
              processedSheet.rowsStatus.push("open");
            } else {
              processedSheet.rowsStatus.push("none");
            }

          } else {
            processedSheet.rowsStatus.push("none");
          }
        }
      },
      removeUnnecessarilyRows(processedSheet) {
        let count = 0;
        if(/summary/i.test(processedSheet.name)){
          count = 2
        }else {
          count = 9
        }
        for (let i = 0; i < count; i++) {
          processedSheet.rows.shift();
        }
      },
      addImageColumn(processedSheet, columnName) {
        let headerLength = 0;
        for (let i = 0; i < processedSheet.rows.length; i++) {
          if (processedSheet.rows[i]) {
            if (processedSheet.rows[i][0]) {
              if (processedSheet.rows[i][0].content) {
                if (processedSheet.rows[i][0].content.match(/no/i)) {
                  headerLength = processedSheet.rows[i].length;
                  processedSheet.rows[i].push({
                    position: "",
                    content: columnName
                  });
                } else {
                  processedSheet.rows[i][headerLength] = {
                    position: "",
                    content: ""
                  };
                }
              } else if (!processedSheet.rows[i][0].content) {
                for (let j = processedSheet.rows[i].length; j <= headerLength; j++) {
                  if (j !== headerLength) {
                    processedSheet.rows[i].push({
                      position: "",
                      content: ""
                    });
                  } else {
                    const rowNumber = processedSheet.rows[i][0].position.match(/\d+/)[0];
                    const columnLetter = this.alphabet[processedSheet.rows[i].length];
                    processedSheet.rows[i][headerLength] = {
                      position: columnLetter + rowNumber,
                      content: "",
                      type: "image"
                    };
                  }
                }
              }
            }
          }
        }
      },
      processSheet(sheetName) {
        const sheet = this.file.Sheets[sheetName];
        const cells = this.getFormattedCells(sheet);
        const pSheet = this.getProcessedSheet(sheetName, cells);
        this.removeUnnecessarilyRows(pSheet);
        this.addImageColumn(pSheet, "Initial Picture");
        this.attachRowPriority(pSheet);
        this.attachRowStatus(pSheet);
        this.processedSheets.push(pSheet);
      },
      getRowColor(row) {
        if (row && row.length > 0 && row[0].content) {
          if (row[0].content.match(/(no)+/i)) {
            return "table-header";
          }
          if (row[0].content.match(/^\d+$/i)) {
            return "table-title";
          }
          if (row[0].content.match(/^\d+\.\d+$/i)) {
            return "table-subtitle";
          }
        }
      },
      getCellColor(cell) {
        if (cell && cell.content && cell.position && /^[eg]\d+/i.test(cell.position)) {
          if (cell.content.match(/critical/i) && /^e\d+/i.test(cell.position)) {
            return "critical";
          }
          if (cell.content.match(/minor/i) && /^e\d+/i.test(cell.position)) {
            return "minor";
          }
          if (cell.content.match(/major/i) && /^e\d+/i.test(cell.position)) {
            return "major";
          }
          if (cell.content.match(/open/i) && /^[g]\d+/i.test(cell.position)) {
            return "open";
          }
          if (cell.content.match(/closed/i) && /^[g]\d+/i.test(cell.position)) {
            return "closed";
          }
          if (cell.content.match(/progress/i) && /^[g]\d+/i.test(cell.position)) {
            return "progress";
          }
        }
      },
      getImagePath(data) {
        if (data) {
          return process.env.VUE_APP_BASE_URL.split("api")[0] + data;
        }
        return process.env.VUE_APP_BASE_URL.split("api")[0] + "uploads/images/logo.png";
      },
      uploadImage(sheetName, row, $event) {
        this.$vs.loading();
        const formData = new FormData();
        formData.append("row", row[0].position.match(/\d+/i)[0]);
        formData.append("image", $event.target.files[0]);
        formData.append("rigUUID", this.$route.params.uuid);
        formData.append("reportUUID", this.reportUUID);
        formData.append("sheetName", sheetName);
        this.$authHTTP.post("reports/audit/upload/image", formData)
          .then(r => {
            console.log(r.data);
            row[row.length - 1].content = r.data;
            this.$vs.notify({ color: "success", title: "Upload Success", text: "Image is uploaded successfully." });
          })
          .catch(e => {
            console.log(e);
            this.$vs.notify({
              color: "danger",
              title: "Upload Failed",
              text: "Error is happened while uploading image"
            });
          })
          .finally(() => {
            this.$vs.loading.close();
            this.updateImage++;
          });
      },
      viewImage(url, row) {
        this.displayImageURL = url;
        this.displayImageTitle = row[2].content;
        this.viewImagePopup = true;
      },
      confirmSave() {
        this.$vs.dialog({
          color: "primary",
          type: "confirm",
          title: "Save Report",
          text: "Are you sure you want to save this report?",
          accept: this.compressFile
        });
      },
      compressFile(){
        this.$vs.loading();
        const zip = new JSZip();
        zip.file(this.sheet.name, this.sheet);
        zip.generateAsync({
          type: "blob",
          compression: "DEFLATE",
          compressionOptions: {
            level: 9
          }
        })
          .then(content=>{
            this.sheet = new File([content], this.sheet.name + ".zip");
            console.log(this.sheet);
            this.saveReports();
          })
          .catch(e=>{
            console.log(e);
          });
      },
      saveReports() {
        this.$vs.loading();
        const formData = new FormData();
        formData.append("reportUUID", this.reportUUID);
        formData.append("rigUUID", this.$route.params.uuid);
        formData.append("data", JSON.stringify(this.processedSheets));
        formData.append("Critical_Closed_Percentage", this.overAllCriticalClosedPercentage);
        formData.append("Major_Closed_Percentage", this.overAllMajorClosedPercentage);
        formData.append("Minor_Closed_Percentage", this.overAllMinorClosedPercentage);
        formData.append("Overall_Closed_Percentage", this.overAllPrioritiesClosedPercentage);
        formData.append("sheet", this.sheet);

        this.$authHTTP.post("reports/audit/save", formData)
          .then(r => {
            console.log(r);
          })
          .catch(e => {
            console.log(e);
          })
          .finally(() => {
            const payload = {
              "reportUUID": this.$uuid.v4(),
              "rigUUID": this.$route.params.uuid,
              "parentUUID": this.reportUUID,
              "data": JSON.stringify(this.closingAuditReport),
              "Critical Open": this.overAllCriticalOpen,
              "Critical In Progress": this.overAllCriticalProgress,
              "Major Open": this.overAllMajorOpen,
              "Major In Progress": this.overAllMajorProgress,
              "Minor Open": this.overAllMinorOpen,
              "Minor In Progress": this.overAllMinorProgress,
              "Overall Open": this.overAllPrioritiesOpen,
              "Overall In Progress": this.overAllPrioritiesProgress
            };
            this.$authHTTP.post("reports/audit_closing/save", payload)
              .then(r => {
                console.log(r);
              })
              .catch(e => {
                console.log(e);
              })
              .finally(() => {
                const final = {
                  "reportUUID": this.$uuid.v4(),
                  "rigUUID": this.$route.params.uuid,
                  "parentUUID": this.reportUUID
                };

                this.$authHTTP.post("reports/audit_final_reports/save", final)
                  .then(r => {
                    console.log(r);
                  })
                  .catch(e => {
                    console.log(e);
                  })
                  .finally(() => {
                    this.$vs.loading.close();
                    this.$router.push("/rig/show/" + this.$route.params.uuid);
                  });
              });
          });
      }
    },
    computed: {
      currentSheet() {
        const selectedSheet = this.processedSheets.find(x => {
          return x.name === this.selectedSheet;
        });
        return selectedSheet ? selectedSheet : {};
      },
      criticalCount() {
        return this.currentSheet.rowsPriority.filter(priority => {
          return priority === "critical";
        }).length;
      },
      majorCount() {
        return this.currentSheet.rowsPriority.filter(priority => {
          return priority === "major";
        }).length;
      },
      minorCount() {
        return this.currentSheet.rowsPriority.filter(priority => {
          return priority === "minor";
        }).length;
      },
      allPriorityCount() {
        return this.criticalCount + this.majorCount + this.minorCount;
      },
      criticalAndClosedCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "critical" && status === "closed") {
            count++;
          }
        }
        return count;
      },
      criticalAndInProgressCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "critical" && status === "progress") {
            count++;
          }
        }
        return count;
      },
      criticalAndOpenCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "critical" && status === "open") {
            count++;
          }
        }
        return count;
      },
      majorAndClosedCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "major" && status === "closed") {
            count++;
          }
        }
        return count;
      },
      majorAndInProgressCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "major" && status === "progress") {
            count++;
          }
        }
        return count;
      },
      majorAndOpenCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "major" && status === "open") {
            count++;
          }
        }
        return count;
      },
      minorAndClosedCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "minor" && status === "closed") {
            count++;
          }
        }
        return count;
      },
      minorAndInProgressCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "minor" && status === "progress") {
            count++;
          }
        }
        return count;
      },
      minorAndOpenCount() {
        let count = 0;
        for (let i in this.currentSheet.rowsPriority) {
          const priority = this.currentSheet.rowsPriority[i];
          const status = this.currentSheet.rowsStatus[i];
          if (priority === "minor" && status === "open") {
            count++;
          }
        }
        return count;
      },
      allOpenStatus() {
        return this.criticalAndOpenCount + this.majorAndOpenCount + this.minorAndOpenCount;
      },
      allInProgressStatus() {
        return this.criticalAndInProgressCount + this.majorAndInProgressCount + this.minorAndInProgressCount;
      },
      allClosedStatus() {
        return this.criticalAndClosedCount + this.majorAndClosedCount + this.minorAndClosedCount;
      },
      criticalClosedPercentage() {
        const percentage = Math.floor((this.criticalAndClosedCount / this.criticalCount) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      majorClosedPercentage() {
        const percentage = Math.floor((this.majorAndClosedCount / this.majorCount) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      minorClosedPercentage() {
        const percentage = Math.floor((this.minorAndClosedCount / this.minorCount) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      allClosedPercentage() {
        const percentage = Math.floor((this.allClosedStatus / this.allPriorityCount) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      overAllCriticalClosedPercentage() {
        let criticalCount = 0;
        let closedCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "critical") {
              criticalCount++;
            }
            if (priority === "critical" && status === "closed") {
              closedCount++;
            }
          }
        }
        const percentage = Math.floor((closedCount / criticalCount) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      overAllCriticalOpen() {
        let openCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "critical" && status === "open") {
              openCount++;
            }
          }
        }
        return openCount;
      },
      overAllCriticalProgress() {
        let inProgressCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "critical" && status === "progress") {
              inProgressCount++;
            }
          }
        }
        return inProgressCount;
      },
      overAllMajorClosedPercentage() {
        let majorCount = 0;
        let closedCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "major") {
              majorCount++;
            }
            if (priority === "major" && status === "closed") {
              closedCount++;
            }
          }
        }
        const percentage = Math.floor((closedCount / majorCount) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      overAllMajorOpen() {
        let openCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "major" && status === "open") {
              openCount++;
            }
          }
        }
        return openCount;
      },
      overAllMajorProgress() {
        let inProgressCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "major" && status === "progress") {
              inProgressCount++;
            }
          }
        }
        return inProgressCount;
      },
      overAllMinorClosedPercentage() {
        let minorCount = 0;
        let closedCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "minor") {
              minorCount++;
            }
            if (priority === "minor" && status === "closed") {
              closedCount++;
            }
          }
        }
        const percentage = Math.floor((closedCount / minorCount) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      overAllMinorOpen() {
        let openCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "minor" && status === "open") {
              openCount++;
            }
          }
        }
        return openCount;
      },
      overAllMinorProgress() {
        let inProgressCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "minor" && status === "progress") {
              inProgressCount++;
            }
          }
        }
        return inProgressCount;
      },
      overAllPrioritiesClosedPercentage() {
        let count = 0;
        let closedCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if (priority === "minor" || priority === "major" || priority === "critical") {
              count++;
            }
            if ((priority === "minor" || priority === "major" || priority === "critical") && status === "closed") {
              closedCount++;
            }
          }
        }
        const percentage = Math.floor((closedCount / count) * 100);
        return isNaN(percentage) ? "---" : percentage;
      },
      overAllPrioritiesOpen() {
        let openCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if ((priority === "minor" || priority === "major" || priority === "critical") && status === "open") {
              openCount++;
            }
          }
        }
        return openCount;
      },
      overAllPrioritiesProgress() {
        let inProgressCount = 0;
        for (const sheet of this.processedSheets) {
          for (const i in sheet.rowsPriority) {
            const priority = sheet.rowsPriority[i];
            const status = sheet.rowsStatus[i];
            if ((priority === "minor" || priority === "major" || priority === "critical") && status === "progress") {
              inProgressCount++;
            }
          }
        }
        return inProgressCount;
      },
      closingAuditReport() {
        const clone = JSON.parse(JSON.stringify(this.processedSheets));
        for (const sheet of clone) {
          this.addImageColumn(sheet, "Final Picture");
          sheet.rows = sheet.rows.filter(row => {
            if (row) {
              let closed = false;
              for (let i = 0; i < row.length; i++) {
                const cell = row[i];
                if (cell && cell.position) {
                  if (cell.content && /^A\d+$/i.test(cell.position)) break;
                  closed = /^g\d+$/i.test(cell.position) && (!cell.content || /.?CLOSED.?/i.test(cell.content));
                  if (closed) break;
                }
              }
              return !closed;
            } else {
              return false;
            }
          });
        }
        return clone;
      },
      QSHEReport() {
        const sheet = this.processedSheets.find(s => /qshe/ig.test(s.name));
        const clone = JSON.parse(JSON.stringify(sheet));
        this.addImageColumn(clone, "Final Picture");
        return clone;
      },
      QSHECriticalOpen() {
        let openCount = 0;
        const sheet = this.processedSheets.find(s => /qshe/ig.test(s.name));
        for (const i in sheet.rowsPriority) {
          const priority = sheet.rowsPriority[i];
          const status = sheet.rowsStatus[i];
          if (priority === "critical" && status === "open") {
            openCount++;
          }
        }

        return openCount;
      },
      QSHECriticalProgress() {
        let inProgressCount = 0;
        const sheet = this.processedSheets.find(s => /qshe/ig.test(s.name));
        for (const i in sheet.rowsPriority) {
          const priority = sheet.rowsPriority[i];
          const status = sheet.rowsStatus[i];
          if (priority === "critical" && status === "progress") {
            inProgressCount++;
          }
        }
        return inProgressCount;
      },
      QSHEMajorOpen() {
        let openCount = 0;
        const sheet = this.processedSheets.find(s => /qshe/ig.test(s.name));
        for (const i in sheet.rowsPriority) {
          const priority = sheet.rowsPriority[i];
          const status = sheet.rowsStatus[i];
          if (priority === "major" && status === "open") {
            openCount++;
          }
        }
        return openCount;
      },
      QSHEMajorProgress() {
        let inProgressCount = 0;
        const sheet = this.processedSheets.find(s => /qshe/ig.test(s.name));
        for (const i in sheet.rowsPriority) {
          const priority = sheet.rowsPriority[i];
          const status = sheet.rowsStatus[i];
          if (priority === "major" && status === "progress") {
            inProgressCount++;
          }
        }
        return inProgressCount;
      },
      QSHEMinorOpen() {
        let openCount = 0;
        const sheet = this.processedSheets.find(s => /qshe/ig.test(s.name));
        for (const i in sheet.rowsPriority) {
          const priority = sheet.rowsPriority[i];
          const status = sheet.rowsStatus[i];
          if (priority === "minor" && status === "open") {
            openCount++;
          }
        }
        return openCount;
      },
      QSHEMinorProgress() {
        let inProgressCount = 0;
        const sheet = this.processedSheets.find(s => /qshe/ig.test(s.name));
        for (const i in sheet.rowsPriority) {
          const priority = sheet.rowsPriority[i];
          const status = sheet.rowsStatus[i];
          if (priority === "minor" && status === "progress") {
            inProgressCount++;
          }
        }
        return inProgressCount;
      },
      QSHEPrioritiesOpen() {
        let openCount = 0;
        const sheet = this.processedSheets.find(s => /qshe/ig.test(s.name));
        for (const i in sheet.rowsPriority) {
          const priority = sheet.rowsPriority[i];
          const status = sheet.rowsStatus[i];
          if ((priority === "minor" || priority === "major" || priority === "critical") && status === "open") {
            openCount++;
          }
        }
        return openCount;
      },
      QSHEPrioritiesProgress() {
        let inProgressCount = 0;
        const sheet = this.processedSheets.find(s => /qshe/ig.test(s.name));
        for (const i in sheet.rowsPriority) {
          const priority = sheet.rowsPriority[i];
          const status = sheet.rowsStatus[i];
          if ((priority === "minor" || priority === "major" || priority === "critical") && status === "progress") {
            inProgressCount++;
          }
        }
        return inProgressCount;
      }
    }
  };
</script>

<style lang="scss" scoped>
  table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    color: #000;

    img {
      cursor: pointer;
      margin-top: 10px;
    }
  }

  table, th, td {
    border: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  td, th {
    width: available;
    padding: 5px;
  }

  .w-100 {
    width: 100% !important;
  }

  .table-header {
    background-color: blue;
    color: #fff;
    font-size: 1.1rem;
    font-weight: bold;
  }

  .table-title {
    background-color: #000;
    color: #fff;
    font-weight: bold;
    font-size: 1rem;
  }

  .table-subtitle {
    background-color: #ccc;
    font-weight: bold;
    font-size: 1rem;
  }

  .critical {
    color: red;
    font-weight: bold;
  }

  .minor {
    color: yellow;
    font-weight: bold;
  }

  .major {
    color: blue;
    font-weight: bold;
  }

  .open {
    background-color: red;
    font-weight: bold;
  }

  .closed {
    background-color: green;
    font-weight: bold;
  }

  .progress {
    background-color: orangered;
    font-weight: bold;
  }

  #summary, #details {
    margin: 10px 0;
  }

  .popup {
    div {
      width: 100%;
      text-align: center;

      img {
        max-width: 100%;
        height: auto;
      }
    }

  }
</style>
