export default {
  home: {
    route: true,
  },
  rigs: {
    route: true,
    show: true,
    add: false,
    edit: false,
    delete: false,
    tabs:{
      specifications: {
        show: true,
        edit: false,
      },
      inspection: {
        show: true,
        preliminary: {
          list: true,
          add:false,
          show: true,
          edit: false,
          delete: false,
          download: false
        },
        closing: {
          list: true,
          show: true,
          edit: false
        },
        final: {
          list: true,
          show: true,
          edit: false,
          download: false,
          files :{
            delete: false,
            download: false,
          }
        },
      },
      audit: {
        show: true,
        preliminary: {
          list: true,
          add: false,
          show: true,
          edit: false,
          download: false
        },
        closing: {
          list: true,
          show: true,
          edit: false
        },
        final: {
          list: true,
          show: true,
          edit: false,
          download: false,
          files :{
            delete: false,
            download: false,
          }
        },
      },
      acceptance: {
        show: true,
        list:{
          show: true,
          add: false,
          edit: false,
          download: false
        }
      },
      stopCard: {
        show: false
      },
      qshe: {
        show: true,
        list:{
          show: true
        }
      },
      moveDrop: {
        show: true,
        move: {
          list: true,
          add:false,
          show: true,
          edit: false,
          download: false,
          files:{
            delete: false,
            download: false
          }
        },
        drop:{
          list: true,
          add: false,
          show: true,
          edit: false,
          download: false,
          files:{
            delete: false,
            download: false
          }
        }
      }
    },
  },
  visits: {
    route: true,
    showDay: true,
    show: true,
    add: false,
    edit: false,
    delete: false
  },
  clients: {
    route: false,
    add: false,
    edit: false,
    delete: false
  },
  users: {
    route: false,
    add: false,
    edit: false,
    delete: false
  },
}
