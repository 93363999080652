<template>
  <vs-row>
    <vs-col vs-w="12">
      <breadcrumb />
    </vs-col>
   <vs-row>
     <vs-col vs-w="12" style="margin-top: 20px">
       <vs-row>
         <vs-col vs-w="5" vs-sm="6" class="px-1">
           <vs-select class="w-100" label="Clients" v-model="selectedClient" placeholder="Choose Client" v-if="clients.length > 0 && $store.state.auth.owner">
             <vs-select-item value="" text="All"></vs-select-item>
             <vs-select-item v-for="(client, index) in clients" :key="index" :value="client.id"
                             :text="client.name"></vs-select-item>
           </vs-select>
         </vs-col>
         <vs-col vs-w="5" vs-sm="6" class="px-1">
           <vs-select class="w-100" label="Activity" v-model="activeClients" placeholder="Choose Activity" v-if="clients.length > 0 && $store.state.auth.owner">
             <vs-select-item value="" text="All"></vs-select-item>
             <vs-select-item value="1" text="Active"></vs-select-item>
             <vs-select-item value="0" text="Inactive"></vs-select-item>

           </vs-select>
         </vs-col>
         <vs-col vs-type="flex" vs-justify="flex-end" class="px-1" vs-w="2" vs-sm="12" style="margin-top: 5px; align-self: flex-end">
           <vs-button @click="openPopup" v-if="permissions.add" style="white-space: nowrap;"><font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Add</vs-button>
         </vs-col>
       </vs-row>
     </vs-col>
     <vs-popup class="holamundo"  title="Add New Rig" :active.sync="addNew">
       <vs-row>
         <vs-col vs-w="6" vs-sm="12" style="padding-left: 25px">
           <vs-input label="Name" v-model="newRigData.name"/>
         </vs-col>
         <vs-col vs-w="6" vs-sm="12" style="padding-left: 25px">
           <vs-select label="Client" v-model="newRigData.client_id" placeholder="Choose Client" v-if="clients.length > 0 && $store.state.auth.owner">
             <vs-select-item v-for="(client, index) in clients" :key="index" :value="client.id"
                             :text="client.name"></vs-select-item>
           </vs-select>
         </vs-col>
         <vs-col vs-w="6" vs-sm="12">
           <vs-upload
             :action="getUploadImageEndPoint"
             automatic
             text="Upload Image"
             limit="1"
             fileName="image"
             accept="image/*"
             :data="{UUID: newRigData.UUID, update: 0}"
             @on-success="successUpload"
             @on-error="failedUpload"
             @on-delete="deletedUpload"
           />
         </vs-col>
         <vs-col vs-w="6" vs-sm="12" vs-type="flex" vs-justify="flex-end" vs-align="flex-end" style="margin-bottom: 20px;">
           <vs-button @click="nextStep" :disabled="!validData">Next &nbsp;<font-awesome-icon icon="arrow-right"></font-awesome-icon></vs-button>
         </vs-col>
       </vs-row>

     </vs-popup>
   </vs-row>
   <vs-row>
     <vs-col vs-w="12">
       <vs-row class="mt-3">
         <vs-col class="mt-3" vs-lg="3" vs-md="6" vs-sm="12" v-for="(rig, index) in listData.data" :key="index">
           <vs-col style="height: 100%" class="p-1">
             <vs-col class="card p-3" style="height: 100%; width: 100%" vs-type="flex" vs-justify="center" vs-align="center">
               <img :src="getImagePath(rig.image)" alt="Rig Image" style="max-width: 100%">
               <vs-col class="overlay p-3" vs-type="flex" vs-justify="center" vs-align="center">
                 <vs-col class="bordered" vs-type="flex" vs-justify="center" vs-align="center" style="flex-direction: column">
                   <p class="text-white mb-3 info">Name: {{rig.name}}</p>
                   <p class="text-white mb-3 info" v-if="$store.state.auth.owner">Client: {{rig.client.name}}</p>
                   <p class="text-white mb-3 info" v-if="$store.state.auth.owner">Client Activity: {{rig.client.active === 1 ? "Active" : "Inactive"}}</p>
                 </vs-col>
                 <crud-actions
                   :permissions="permissions"
                   :id="rig.UUID"
                   class="actions mt-3 space-around w-100"
                   @viewClicked="viewItem"
                   @editClicked="editItem"
                   @trashClicked="confirmDelete"></crud-actions>
               </vs-col>
             </vs-col>
             <p class="text-center bold">{{rig.name}}</p>
           </vs-col>
         </vs-col>
       </vs-row>
       <!--<vs-table search stripe :max-items="listData.per_page" :data="listData.data">
         <template slot="header">
           <h3>
             Rigs
           </h3>
         </template>
         <template slot="thead">
           <vs-th>
             No.
           </vs-th>
           <vs-th>
             Image
           </vs-th>
           <vs-th>
             Name
           </vs-th>
           <vs-th v-if="$store.state.auth.owner">
             Client
           </vs-th>
           <vs-th v-if="$store.state.auth.owner">
             Client Activity
           </vs-th>
           <vs-th>
             Actions
           </vs-th>
         </template>

         <template slot-scope="{data}">
           <vs-tr :key="indextr" v-for="(tr, indextr) in data" >

             <vs-td :data="itemOrder(tr)">
               {{ ((listData.current_page - 1) * listData.per_page) + itemOrder(tr) }}
             </vs-td>
             <vs-td :data="tr.image">
               <img :src="getImagePath(tr.image)" alt="Rig Image" width="100" height="50">
             </vs-td>

             <vs-td :data="tr.name">
               {{tr.name}}
             </vs-td>
             <vs-td :data="tr.client.name" v-if="$store.state.auth.owner">
               {{tr.client.name}}
             </vs-td>
             <vs-td :data="tr.client.active" v-if="$store.state.auth.owner">
               {{tr.client.active === 1 ? "Active" : "Inactive" }}
             </vs-td>
             <vs-td :data="tr">
               <crud-actions
                 :permissions="permissions"
                 :id="tr.UUID"
                 @viewClicked="viewItem"
                 @editClicked="editItem"
                 @trashClicked="confirmDelete"></crud-actions>
             </vs-td>
           </vs-tr>
         </template>
       </vs-table> -->
       <Pagination url="rigs/list" :params="`client=${this.selectedClient}&activity=${this.activeClients}`" :list-data="listData" @data-changed="listData = $event"></Pagination>
     </vs-col>
   </vs-row>
  </vs-row>
</template>

<script>
  import CrudActions from "../../../layouts/components/CrudActions";
  import Breadcrumb from "../../../layouts/components/Breadcrumb";
  import Pagination from "../../../layouts/components/Pagination";
  import store2 from "store2";
    export default {
        name: "list",
      components: {
          CrudActions,
        Breadcrumb,
        Pagination
      },
      data(){
          return {
            listData: {},
              newRigData: {
              name: "",
              UUID: "",
              image: null,
              client_id: ""
            },
            addNew: false,
            clients: [],
            selectedClient: "",
            activeClients: ""
          }
      },
      methods: {
        getData(){
          this.$vs.loading();
          this.$authHTTP.get(`rigs/list?client=${this.selectedClient}&activity=${this.activeClients}`)
            .then(r => {
              this.$vs.loading.close()
              this.listData = r.data;
            })
            .catch(e => {
              this.$vs.loading.close()
              console.log(e);
            });
        },
        getClients(){
          this.$vs.loading();
          this.$authHTTP.get("clients/all")
            .then(r => {
              this.$vs.loading.close()
              this.clients = r.data.filter(client => client.id !== 1);
            })
            .catch(e => {
              this.$vs.loading.close()
              console.log(e);
            });
        },
        itemOrder(data){
          const index = this.listData.data.findIndex(item => {
            return item.id === data.id;
          });
          return index + 1;
        },
        getImagePath(data){
          if(data) {
            return process.env.VUE_APP_BASE_URL.split('api')[0] + data;
          }
          return process.env.VUE_APP_BASE_URL.split('api')[0] + 'uploads/images/logo.png';
        },
        viewItem(uuid){
          this.$router.push('rig/show/' + uuid);
        },
        editItem(uuid){
          this.$router.push('rig/edit/' + uuid);
        },
        deleteItem(uuid){
          this.$vs.loading();
          this.$authHTTP.delete('rigs/delete-complete-rig/' + uuid)
            .then(r => {
              const index = this.listData.data.findIndex(x =>  x.UUID === uuid );
              this.listData.data.splice(index, 1);
              this.$vs.notify({
                color: "success",
                title: "Success",
                text: r.data
              });
            }).catch(e => {
            console.log(e);
            this.$vs.notify({
              title: 'Error',
              text: e,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            });
          }).finally(() => {
            this.$vs.loading.close();
          });
        },
        confirmDelete(id){
          this.$vs.dialog({
            title: "Confirm",
            text: "Are you sure that you want to delete this item?",
            color: "danger",
            acceptText: "I'm sure",
            accept: () => this.deleteItem(id),
            type: "confirm"
          });
        },
        openPopup(){
          this.addNew = true;
          this.newRigData.UUID = this.$uuid.v4()
        },
        nextStep(){
          this.$vs.loading();
          this.addNew = false;
          this.$authHTTP.post("rigs/save-new-rig", this.newRigData)
          .then(r => {
            if(r){
              this.$router.push("rig/add/" + this.newRigData.UUID);
            }else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: "Error is happened!"
              });
              this.addNew = true;
            }
            this.$vs.loading.close();
          })
          .catch(e => {
            console.log(e);
            this.$vs.loading.close();
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: "Error is happened!"
            });
            this.addNew = true;
          })
        },
        successUpload(data){
          this.newRigData.image = data.currentTarget.responseText;
          this.$vs.notify({color:'success',title:'Upload Success',text:'Image is uploaded successfully.'});
        },
        failedUpload(){
          this.$vs.notify({color:'danger',title:'Upload Failed',text:'Unable to upload image.'});
        },
        deletedUpload(){
          this.newRigData.image = null;
          this.$vs.notify({color:'success',title:'Delete Success',text:'Image is deleted successfully.'});
        }
      },
      computed: {
        validData(){
          if(this.newRigData.name && this.newRigData.name.length > 3){
            if(this.$store.state.auth.owner){
              return !!this.newRigData.client_id;
            }
            return true;
          }
          return false;
        },
        getUploadImageEndPoint(){
          return process.env.VUE_APP_BASE_URL + "/rigs/upload/image?token=" + store2.get("access_token");
        },
        permissions(){
          return this.$store.state.auth.permissions.rigs;
        }
      },
      watch:{
        selectedClient(){
          this.getData();
        },
        activeClients(){
          this.getData();
        }
      },
      mounted() {
        this.getData();
        if(this.$store.state.auth.owner){
          this.getClients();
        }
      }
    };
</script>

<style lang="scss">
.disabled-upload {
  display: none !important;
}
  .radio {
    margin-left: 10px;
    margin-right: 10px;
  }

  .card {
    border: 1px solid #ccc;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    min-height: 200px;
  }

  .bold {
    font-weight: bold;
  }

  .overlay{
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.6);
    bottom: 100%;
    transition: all 0.5s;
    flex-direction: column;
  }

  .card *, .card ~ *{
    transition: all 0.15s;
  }

  .card:hover {
    img {
      transform: scale(1.1);
    }
    .overlay {
      bottom: 0;
    }
    ~ p {
      visibility: hidden;
    }
  }
  .d-block {
    display: block !important;
  }

  .info {
    font-size: 1.2rem;
  }

  .actions {
    color: white;
  }

  .space-around {
    justify-content: space-around !important;
  }

  .bordered {
    border: 1px solid rgba(0,0,0,0.9);
    border-radius: 10px;
  }
</style>
